import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import * as yup from 'yup';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ServiceTypes from './ServiceTypes';
import ShipperDelivery from './ShipperDelivery';
import ShipperPickUP from './ShipperPickUP';
import CargoList from './CargoList';
import QuoteSummary from './QuoteSummary';
import RenderGoogleMap from './RenderGoogleMap';

import {
  formatPickupDeliveryDate,
  formatPickupDeliveryTime
} from '../../../utils/date_conversion.util';
import { useAuthContext } from '../../../contexts/auth.context';
import {
  addQuoteService,
  getQuoteDetailsService
} from '../../../services/quote.service';

import {
  BLANKET_WRAP,
  DELIVERY_LIMITED_ACCESS,
  DELIVERY_TRADE_SHOW,
  LTL_FREIGHT,
  PICKUP_LIMITED_ACCESS,
  TRUCKLOAD_FLAT,
  TRUCKLOAD_VAN
} from '../../../constants/service_type.constants';
import Roles from '../../../constants/roles.constants';
import { BID_TYPE } from '../../../constants/bids.constants';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';
import ShipperSinglePickUP from './ShipperSinglePickup';
import ShipperSingleDelivery from './ShipperSingleDelivery';
import { EquipmentTypes } from '../../../constants/carrier.constants';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { isDuplicate, VIEW_TYPE } from '../../../constants/generalConstants';
import QuickQuotePage from './QuickQuotePage';

const NewQuotePage = () => {
  const { authUser } = useAuthContext();
  const {
    viewType,
    setViewType,
    setIsQuickQuoteEnabled,
    setQuickQuoteForm,
    clearQuickQuoteForm
  } = useContext(GlobalFormContext);
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [compiledForm, setCompiledForm] = useState({});
  const [serviceType, setserviceType] = useState();
  const [zipError, setZipError] = useState(false);
  const [changedPickups, setChangedPickups] = useState([]);
  const [changedDeliveries, setChangedDeliveries] = useState([]);
  const [quoteDataValue, setQuoteDataValue] = useState(null);

  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const { QUICK_VIEW } = VIEW_TYPE;

  const validationSchema = [
    // validation for step1
    yup.object({
      service_type: yup.mixed().required('Please select a Service Type.'),
      company: yup.mixed().when('dummy', {
        is: () =>
          [Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
            authUser.user.usertype
          ),
        then: () => yup.mixed().required('Please select a Customer.'),
        otherwise: () => yup.mixed()
      }),
      responsible_user: yup.mixed().when('dummy', {
        is: () =>
          [Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
            authUser.user.usertype
          ),
        then: () => yup.mixed().required('Please select a Responsible User.'),
        otherwise: () => yup.mixed()
      })
    }),
    // validation for step2
    yup.object({
      pickup_from: yup.string().required('Pickup from is required'),
      pickup_formatted_address: yup
        .string()
        .required('Pickup formatted address is required'),
      pickup_zip_code: yup.string().required('Zip code is required'),
      pickup_date: yup.string().when('is_pickup_flexible', {
        is: false,
        then: () => yup.string().required('Pick up date is required')
      }),
      is_pickup_flexible: yup.bool(),
      pickup_time: yup.string().when('is_pickup_time_flexible', {
        is: false,
        then: () => yup.string().required('Pick up time is required')
      }),
      pickup_close_time: yup.string(),
      is_pickup_time_flexible: yup.bool(),
      pickup_service_options: yup.mixed().nullable(),
      pickup_special_instructions: yup.string(),
      pickup_limited_access_type: yup.mixed().when('pickup_service_options', {
        is: (data) => data?.includes(PICKUP_LIMITED_ACCESS),
        then: () =>
          yup.mixed().required('Pickup limited access type is required')
      }),
      id: yup.string()
    }),
    // validation for step3
    yup.object({
      delivery_to: yup.string().required('Delivery from is required'),
      delivery_formatted_address: yup
        .string()
        .required('Delivery formatted address is required'),

      delivery_zip_code: yup.string().required('Zip code is required'),
      delivery_date: yup.string().when('is_delivery_flexible', {
        is: false,
        then: () => yup.string().required('Delivery date is required')
      }),
      is_delivery_flexible: yup.bool(),
      delivery_time: yup.string().when('is_delivery_time_flexible', {
        is: false,
        then: () => yup.string().required('Delivery time is required')
      }),
      delivery_close_time: yup.string(),
      is_delivery_time_flexible: yup.bool(),
      delivery_service_options: yup.array().nullable(),
      delivery_special_instructions: yup.string(),
      delivery_limited_access_type: yup
        .mixed()
        .when('delivery_service_options', {
          is: (data) => data?.includes(DELIVERY_LIMITED_ACCESS),
          then: () =>
            yup.mixed().required('Delivery limited access type is required')
        }),
      delivery_tradeshow_type: yup.mixed().when('delivery_service_options', {
        is: (data) => data?.includes(DELIVERY_TRADE_SHOW),
        then: () => yup.mixed().required('Delivery trade show type is required')
      }),
      id: yup.string()
    }),
    // validation for step4
    yup.object({
      cargos: yup
        .array()
        .of(
          yup.object().shape({
            goods_condition: yup
              .mixed()
              .required('Goods Condition is required'),
            freight_class: yup.mixed().when('dummy', {
              is: () => serviceType === LTL_FREIGHT,
              then: () => yup.mixed().required('Freight Class is required'),
              otherwise: () => yup.mixed().nullable()
            }),
            nmfc_code: yup.mixed().when('dummy', {
              is: () => serviceType === LTL_FREIGHT,
              then: () => yup.mixed().nullable(),
              otherwise: () => yup.mixed().nullable()
            }),
            goods_type: yup.array().nullable(),
            ship_from: yup.mixed().when('dummy', {
              is: () => ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType),
              then: () =>
                yup.string().nullable().required('Ship from is required'),
              otherwise: () => yup.string().nullable()
            }),
            ship_to: yup.mixed().when('dummy', {
              is: () => ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType),
              then: () =>
                yup.string().nullable().required('Ship to is required'),
              otherwise: () => yup.string().nullable()
            }),
            package_type: yup
              .mixed()
              .required('Cargo Package type is required'),
            width: yup.number().when('dummy', {
              is: () =>
                serviceType === TRUCKLOAD_FLAT || serviceType === TRUCKLOAD_VAN,
              then: () =>
                yup.number().transform((cv, ov) => {
                  return ov === '' ? undefined : cv;
                }),
              otherwise: () =>
                yup
                  .number()
                  .transform((cv, ov) => {
                    return ov === '' || ov === 0 ? undefined : cv;
                  })
                  .required('Cargo width is required')
            }),
            length: yup.number().when('dummy', {
              is: () =>
                serviceType === TRUCKLOAD_FLAT || serviceType === TRUCKLOAD_VAN,
              then: () =>
                yup.number().transform((cv, ov) => {
                  return ov === '' ? undefined : cv;
                }),
              otherwise: () =>
                yup
                  .number()
                  .transform((cv, ov) => {
                    return ov === '' || ov === 0 ? undefined : cv;
                  })
                  .required('Cargo length is required')
            }),
            height: yup.number().when('dummy', {
              is: () =>
                serviceType === TRUCKLOAD_FLAT || serviceType === TRUCKLOAD_VAN,
              then: () =>
                yup.number().transform((cv, ov) => {
                  return ov === '' ? undefined : cv;
                }),
              otherwise: () =>
                yup
                  .number()
                  .transform((cv, ov) => {
                    return ov === '' || ov === 0 ? undefined : cv;
                  })
                  .required('Cargo height is required')
            }),
            weight: yup
              .number()
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo weight is required'),
            desc: yup.string().required('Cargo description is required'),
            quantity: yup
              .number()
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .default(1),
            tarps: yup.bool(),
            files: yup.string().nullable(),
            cargo_img: yup.string(),
            version: yup.string().default('v3'),
            cargo_id: yup.string().default()
          })
        )
        .min(1, 'Please add at least one cargo!')
        .required('Please add at least one cargo!')
    }),
    // validation for step5
    yup.object({
      bidType: yup
        .number('Please select any Bid Type')
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .default(1)
        .required('Please select any Bid Type'),
      is_insurance_required: yup.bool(),
      insurance_value: yup
        .number('Amount must be a number')
        .nullable()
        .transform((cv, ov) => {
          return ov === '' || Number.isNaN(ov) ? null : cv;
        })
        .when(['is_insurance_required'], {
          is: (isInsurance) => isInsurance,
          then: () =>
            yup
              .number()
              .nullable()
              .transform((cv, ov) => {
                return ov === '' || Number.isNaN(ov) ? null : cv;
              })
              .required('Insurance amount is required'),
          otherwise: () =>
            yup
              .number()
              .nullable()
              .transform((cv, ov) => {
                return ov === '' || Number.isNaN(ov) ? null : cv;
              })
              .notRequired()
        }),
      amount: yup
        .number()
        .transform((cv, ov) => {
          return ov === '' ? undefined : cv;
        })
        .when('bidType', {
          is: 2,
          then: () =>
            yup
              .number()
              .transform((cv, ov) => {
                return ['', '0', 0].includes(ov) ? undefined : cv;
              })
              .required('Please enter a fixed amount')
        }),
      isAutoConverted: yup.bool()
    })
  ];

  const buttonLabels = [
    'Continue',
    'Select Delivery',
    'Proceed',
    // 'Add Cargo',
    'Done',
    'Submit'
  ];

  const currentValidationSchema = validationSchema[step - 1];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    watch,
    control,
    formState: { isValid, isSubmitting },
    reset,
    trigger
  } = methods;
  const form = watch();
  const serviceTypeVal = watch('service_type');

  // append,
  const {
    fields: cargoFields,
    update: cargoUpdate,
    remove: cargoRemove,
    append: cargoAppend
  } = useFieldArray({
    shouldUnregister: false,
    name: 'cargos',
    control,
    keyName: 'cargo_id'
  });

  const {
    fields: pickupFields,
    update: pickupUpdate,
    remove: pickupRemove,
    append: pickupAppend
  } = useFieldArray({
    shouldUnregister: false,
    name: 'pickupInfo',
    control,
    keyName: 'pickup_id'
  });

  const {
    fields: deliveryFields,
    update: deliveryUpdate,
    remove: deliveryRemove,
    append: deliveryAppend
  } = useFieldArray({
    shouldUnregister: false,
    name: 'deliveryInfo',
    control,
    keyName: 'delivery_id'
  });

  const prevStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
      switch (step) {
        case 2:
          setCompiledForm({ ...compiledForm, two: form });
          break;

        case 3:
          setCompiledForm({ ...compiledForm, three: form });
          break;

        case 4:
          setCompiledForm({ ...compiledForm, four: form });
          break;

        case 5:
          setCompiledForm({ ...compiledForm, five: form });
          break;
        default:
          break;
      }
    }
  };

  const nextStep = async () => {
    let isStepValid;

    switch (step) {
      case 1:
        isStepValid = await trigger(
          ['service_type', 'company', 'equipmentType', 'responsible_user'],
          {
            shouldFocus: true
          }
        );
        break;

      case 2:
        isStepValid = await trigger(
          ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType)
            ? ['pickupInfo']
            : [
                'pickup_zip_code',
                'pickup_from',
                'pickup_formatted_address',
                'pickup_date',
                'is_pickup_flexible',
                'pickup_time',
                'pickup_close_time',
                'is_pickup_time_flexible',
                'pickup_service_options',
                'pickup_special_instructions',
                'pickup_limited_access_type',
                'pickupLocation'
              ],
          { shouldFocus: true }
        );
        break;

      case 3:
        isStepValid = await trigger(
          ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType)
            ? ['deliveryInfo']
            : [
                'delivery_zip_code',
                'delivery_to',
                'delivery_formatted_address',
                'delivery_date',
                'is_delivery_flexible',
                'delivery_time',
                'delivery_close_time',
                'is_delivery_time_flexible',
                'delivery_service_options',
                'delivery_special_instructions',
                'delivery_limited_access_type',
                'delivery_tradeshow_type',
                'deliveryLocation'
              ]
        );
        break;

      case 4:
        isStepValid = await trigger(['cargos']);
        break;

      case 5:
        isStepValid = await trigger(
          [
            'bidType',
            'is_insurance_required',
            'insurance_value',
            'amount',
            'save_later',
            'isAutoConverted'
          ],
          {
            shouldFocus: true
          }
        );
        break;

      default:
        break;
    }

    if (isStepValid || isValid) {
      switch (step) {
        case 1:
          setCompiledForm({ ...compiledForm, one: form });
          setStep((prev) => prev + 1);
          break;

        case 2:
          setCompiledForm({ ...compiledForm, two: form });
          setStep((prev) => prev + 1);
          break;

        case 3:
          setCompiledForm({ ...compiledForm, three: form });
          setStep((prev) => prev + 1);
          break;

        case 4:
          setCompiledForm({ ...compiledForm, four: form });
          setStep((prev) => prev + 1);
          break;

        case 5:
          setCompiledForm({ ...compiledForm, five: form });

          break;

        default:
          break;
      }
    }
  };

  const GetStepContent = (formContent) => {
    switch (step) {
      case 1:
        return (
          <ServiceTypes
            nextClick={nextStep}
            {...{ formContent }}
            quoteData={quoteDataValue}
          />
        );

      case 2:
        return ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) ? (
          <ShipperPickUP
            pickups={pickupFields}
            addPickup={pickupAppend}
            updatePickup={pickupUpdate}
            removePickup={pickupRemove}
            prevStep={prevStep}
            nextClick={nextStep}
            changedPickups={changedPickups}
            setChangedPickups={setChangedPickups}
            {...{ formContent }}
          />
        ) : (
          <ShipperSinglePickUP
            prevStep={prevStep}
            nextClick={nextStep}
            {...{ formContent }}
          />
        );

      case 3:
        return ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) ? (
          <ShipperDelivery
            deliveries={deliveryFields}
            addDelivery={deliveryAppend}
            updateDelivery={deliveryUpdate}
            removeDelivery={deliveryRemove}
            prevStep={prevStep}
            nextClick={nextStep}
            changedDeliveries={changedDeliveries}
            setChangedDeliveries={setChangedDeliveries}
            {...{ formContent }}
          />
        ) : (
          <ShipperSingleDelivery
            prevStep={prevStep}
            nextClick={nextStep}
            {...{ formContent }}
          />
        );

      case 4:
        return (
          <CargoList
            cargos={cargoFields}
            addCargo={cargoAppend}
            updateCargo={cargoUpdate}
            removeCargo={cargoRemove}
            prevStep={prevStep}
            nextStep={nextStep}
            trigger={trigger}
            changedPickups={changedPickups}
            changedDeliveries={changedDeliveries}
            {...{ formContent }}
          />
        );

      case 5: {
        return <QuoteSummary prevStep={prevStep} formContent={form} />;
      }

      default:
        break;
    }
  };

  const onSubmitForm = async (formData) => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });

    try {
      const data = { ...formData };
      let tarpsRequired = false;
      if (zipError) return toast.error('Invalid pickup/delivery zip code!');
      if ([LTL_FREIGHT, BLANKET_WRAP].includes(data.service_type)) {
        delete data.pickupInfo;
        delete form.pickupInfo;
        delete data.deliveryInfo;
        delete form.deliveryInfo;
        data.isMultipleQuote = false;
        form.isMultipleQuote = false;
      }
      const cargos = await data.cargos.map((cargo) => {
        const newCargo = {
          quantity: Number.parseInt(cargo.quantity, 10),
          package_type: cargo.package_type,
          goods_condition: cargo.goods_condition.value,
          freight_class:
            cargo?.freight_class?.value && cargo?.freight_class?.value !== '0'
              ? cargo.freight_class.value
              : '',
          nmfc_code: cargo?.nmfc_code ? cargo?.nmfc_code : '',
          height: cargo.height ? Number.parseInt(cargo.height, 10) : 0,
          width: cargo.width ? Number.parseInt(cargo.width, 10) : 0,
          length: cargo.length ? Number.parseInt(cargo.length, 10) : 0,
          weight: Number.parseInt(cargo.weight, 10),
          desc: cargo.desc,
          tarps: cargo.tarps,
          goods_type: cargo?.goods_type?.length ? cargo.goods_type : [],
          ship_from: cargo?.ship_from,
          ship_to: cargo?.ship_to,
          version: 'v3'
        };
        if (cargo.files) {
          newCargo.files = cargo.files;
        } else {
          newCargo.files = process.env.REACT_APP_CARGO_PLACEHOLDER;
        }

        if (cargo.tarps) {
          tarpsRequired = true;
        }
        return newCargo;
      });
      const isMultipleQuote = false;
      let pickupData;
      let deliveryData;

      pickupData = {
        pickup_zip_code: form.pickup_zip_code,
        pickup_state: form.pickup_state,
        pickup_from: form.pickup_from,
        pickup_formatted_address: form.pickup_formatted_address,
        pickup_date: formatPickupDeliveryDate(
          form?.pickup_date,
          form?.is_pickup_flexible
        ),
        is_pickup_flexible: form.is_pickup_flexible,
        is_pickup_time_flexible: form.is_pickup_time_flexible,
        pickup_time: formatPickupDeliveryTime(
          form.pickup_time,
          form.is_pickup_time_flexible
        ),
        pickup_close_time: !form.pickup_close_time
          ? ''
          : formatPickupDeliveryTime(form.pickup_close_time, false),
        pickup_special_instructions: form.pickup_special_instructions,
        pickup_service_options: form?.pickup_service_options,
        pickup_address: form.pickup_address
      };

      deliveryData = {
        delivery_zip_code: form.delivery_zip_code,
        delivery_state: form.delivery_state,
        delivery_to: form.delivery_to,
        delivery_formatted_address: form.delivery_formatted_address,
        delivery_date: formatPickupDeliveryDate(
          form?.delivery_date,
          form?.is_delivery_flexible
        ),
        is_delivery_flexible: form.is_delivery_flexible,
        is_delivery_time_flexible: form.is_delivery_time_flexible,
        delivery_time: formatPickupDeliveryTime(
          form.delivery_time,
          form.is_delivery_time_flexible
        ),
        delivery_close_time: !form.delivery_close_time
          ? ''
          : formatPickupDeliveryTime(form.delivery_close_time, false),
        delivery_special_instructions: form.delivery_special_instructions,
        delivery_service_options: form.delivery_service_options,
        delivery_address: form.delivery_address
      };

      if ([LTL_FREIGHT, BLANKET_WRAP].includes(serviceType)) {
        pickupData.pickup_id = uuidv4();
        deliveryData.delivery_id = uuidv4();
        cargos[0].ship_from = pickupData.pickup_id;
        cargos[0].ship_to = deliveryData.delivery_id;
      }

      if (data.pickupLocation) {
        if (typeof data.pickupLocation === 'string') {
          pickupData.pickupLocation = data.pickupLocation;
        } else {
          pickupData.pickupLocation = data?.pickupLocation?.id;
        }
      }
      if (data.deliveryLocation) {
        if (typeof data.deliveryLocation === 'string') {
          deliveryData.deliveryLocation = data.deliveryLocation;
        } else {
          deliveryData.deliveryLocation = data?.deliveryLocation?.id;
        }
      }

      if (
        ![LTL_FREIGHT, BLANKET_WRAP].includes(form.service_type) &&
        form?.pickupInfo?.length === 1 &&
        form?.deliveryInfo?.length === 1
      ) {
        const pickupInfoData = form?.pickupInfo?.[0];
        const deliveryInfoData = form?.deliveryInfo?.[0];

        pickupData = {
          pickup_id: pickupInfoData?.pickup_id,
          pickup_zip_code: pickupInfoData?.pickup_zip_code,
          pickup_state: pickupInfoData?.pickup_state ?? form?.pickup_state,
          pickup_from: pickupInfoData?.pickup_from,
          pickup_formatted_address: pickupInfoData?.pickup_formatted_address,
          pickup_date: formatPickupDeliveryDate(
            pickupInfoData?.pickup_date,
            pickupInfoData?.is_pickup_flexible
          ),
          is_pickup_flexible: pickupInfoData?.is_pickup_flexible,
          is_pickup_time_flexible: pickupInfoData?.is_pickup_time_flexible,
          pickup_time: formatPickupDeliveryTime(
            pickupInfoData.pickup_time,
            ['', 'flexible time'].includes(pickupInfoData.pickup_time)
          ),
          pickup_close_time: !pickupInfoData?.pickup_close_time
            ? ''
            : formatPickupDeliveryTime(
                pickupInfoData?.pickup_close_time,
                false
              ),
          pickup_special_instructions:
            pickupInfoData?.pickup_special_instructions,
          pickup_service_options: pickupInfoData?.pickup_service_options,
          pickup_address: form.pickup_address
        };

        deliveryData = {
          delivery_id: deliveryInfoData?.delivery_id,
          delivery_zip_code: deliveryInfoData.delivery_zip_code,
          delivery_state:
            deliveryInfoData.delivery_state ?? form?.delivery_state,
          delivery_to: deliveryInfoData.delivery_to,
          delivery_formatted_address:
            deliveryInfoData.delivery_formatted_address,
          delivery_date: formatPickupDeliveryDate(
            deliveryInfoData.delivery_date,
            deliveryInfoData.is_delivery_flexible
          ),
          is_delivery_flexible: deliveryInfoData.is_delivery_flexible,
          is_delivery_time_flexible: deliveryInfoData.is_delivery_time_flexible,
          delivery_time: formatPickupDeliveryTime(
            deliveryInfoData.delivery_time,
            ['', 'flexible time'].includes(deliveryInfoData.delivery_time)
          ),
          delivery_close_time: !deliveryInfoData.delivery_close_time
            ? ''
            : formatPickupDeliveryTime(
                deliveryInfoData.delivery_close_time,
                false
              ),
          delivery_special_instructions:
            deliveryInfoData.delivery_special_instructions,
          delivery_service_options: deliveryInfoData.delivery_service_options,
          delivery_address: form.delivery_address
        };
        if (pickupInfoData?.pickupLocation) {
          if (typeof pickupInfoData?.pickupLocation === 'string') {
            pickupData.pickupLocation = pickupInfoData?.pickupLocation;
          } else {
            pickupData.pickupLocation = pickupInfoData?.pickupLocation.id;
          }
        }
        if (deliveryInfoData?.deliveryLocation) {
          if (typeof deliveryInfoData?.deliveryLocation === 'string') {
            deliveryData.deliveryLocation = deliveryInfoData?.deliveryLocation;
          } else {
            deliveryData.deliveryLocation =
              deliveryInfoData?.deliveryLocation.id;
          }
        }
      }
      let body = {
        service_type: form.service_type,
        responsibleUser:
          authUser.user.usertype === Roles.SHIPPER
            ? authUser?.user?.id
            : form?.responsible_user?.id,
        ...pickupData,
        ...deliveryData,
        service_options_type: [
          form.pickup_limited_access_type,
          form.delivery_limited_access_type,
          form.delivery_tradeshow_type
        ]
          ?.filter((type) => type)
          .map((option) => option.value),
        cargos,
        is_insurance_required: form.is_insurance_required,
        insurance_value: form.insurance_value,
        is_tarps_required: tarpsRequired,
        save_later: form.save_later,
        bidType: form.bidType,
        company: [Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
          authUser.user.usertype
        )
          ? form.company.id
          : authUser.user.companyId.id,
        isAutoConverted: form.isAutoConverted || false,
        version: 'v3',
        isMultipleQuote
      };
      if (form?.pickupInfo?.length > 1 || form?.deliveryInfo?.length > 1) {
        form.pickupInfo = form.pickupInfo?.map((pick) => {
          const pickupCopy = JSON.parse(JSON.stringify(pick));
          pickupCopy.pickup_date = formatPickupDeliveryDate(
            pickupCopy.pickup_date,
            pickupCopy.is_pickup_flexible
          );
          pickupCopy.pickup_time = formatPickupDeliveryTime(
            pickupCopy.pickup_time,
            pickupCopy.is_pickup_time_flexible
          );
          pickupCopy.pickup_close_time = !pickupCopy.pickup_close_time
            ? ''
            : formatPickupDeliveryTime(pickupCopy.pickup_close_time, false);

          if (
            pickupCopy?.pickupLocation &&
            typeof pickupCopy?.pickupLocation !== 'string'
          ) {
            pickupCopy.pickupLocation = pickupCopy?.pickupLocation?.id;
          }

          delete pickupCopy?.pickupLatitude;
          delete pickupCopy?.pickupLongitude;

          return pickupCopy;
        });
        form.deliveryInfo = form.deliveryInfo?.map((del) => {
          const deliveryCopy = JSON.parse(JSON.stringify(del));
          deliveryCopy.delivery_date = formatPickupDeliveryDate(
            deliveryCopy.delivery_date,
            deliveryCopy.is_delivery_flexible
          );
          deliveryCopy.delivery_time = formatPickupDeliveryTime(
            deliveryCopy.delivery_time,
            deliveryCopy.is_delivery_time_flexible
          );
          deliveryCopy.delivery_close_time = !deliveryCopy.delivery_close_time
            ? ''
            : formatPickupDeliveryTime(deliveryCopy.delivery_close_time, false);

          if (
            deliveryCopy?.deliveryLocation &&
            typeof deliveryCopy?.deliveryLocation !== 'string'
          ) {
            deliveryCopy.deliveryLocation = deliveryCopy?.deliveryLocation?.id;
          }

          delete deliveryCopy?.deliveryLatitude;
          delete deliveryCopy?.deliveryLongitude;
          return deliveryCopy;
        });
        body = {
          ...form,
          responsibleUser:
            authUser.user.usertype === Roles.SHIPPER
              ? authUser?.user?.id
              : form?.responsible_user?.id,
          isMultipleQuote: true,
          company: [Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
            authUser.user.usertype
          )
            ? form?.company?.id
            : authUser?.user?.companyId?.id
        };
        Object.keys(body).forEach((key) => {
          if (
            !['pickupInfo', 'deliveryInfo'].includes(key) &&
            (key.includes('pickup') || key.includes('delivery'))
          ) {
            delete body[key];
          }
        });
        const newCargos = body.cargos.map((cg) => {
          const cgCopy = JSON.parse(JSON.stringify(cg));
          delete cgCopy?.freight_class;
          delete cgCopy?.cargo_img;
          delete cgCopy?.createdAt;
          delete cgCopy?.id;
          delete cgCopy?.isDefault;
          delete cgCopy?.quote;
          delete cgCopy?.updatedAt;
          delete cgCopy?.version;
          delete cgCopy?.tarps;
          delete cgCopy?.companyId; // not sure about this
          return cgCopy;
        });
        body.cargos = newCargos;
      }

      if (form.bidType === FIXED_AMOUNT || form.bidType === '2') {
        body.amount = form.amount;
      }
      if (form.bidType === OPEN_BID || form.bidType === '3') {
        body.bookDateTime = moment().format('MM/DD/YYYY HH:mm');
      }
      if (form?.equipmentType && form?.equipmentType?.length) {
        body.equipmentType = form?.equipmentType?.map(
          (equipment) => equipment.value
        );
      }

      let finalBody = {
        service_type: body.service_type,
        bidType: body.bidType,
        company: body.company,
        responsibleUser: body.responsibleUser,
        cargos: body.cargos,
        insurance_value: body.insurance_value,
        is_insurance_required: body.is_insurance_required,
        isMultipleQuote: body.isMultipleQuote,
        is_tarps_required: tarpsRequired,
        version: 'v3'
      };

      if (
        body?.service_type.toString() !== LTL_FREIGHT && // includes syntax was not working here
        body.service_type.toString() !== BLANKET_WRAP &&
        body?.equipmentType &&
        Array.isArray(body.equipmentType) &&
        body.equipmentType.length > 0
      ) {
        finalBody.equipmentType = body.equipmentType;
      }

      if (form.bidType === FIXED_AMOUNT || form.bidType === '2') {
        finalBody.amount = body.amount;
      }
      if (form.bidType === OPEN_BID || form.bidType === '3') {
        finalBody.bookDateTime = body.bookDateTime;
      }

      if (body.isMultipleQuote) {
        finalBody = {
          ...finalBody,
          deliveryInfo: body.deliveryInfo,
          pickupInfo: body.pickupInfo
        };
      } else {
        finalBody = {
          ...finalBody,
          delivery_close_time: body.delivery_close_time,
          delivery_date: body.delivery_date,
          delivery_formatted_address: body.delivery_formatted_address,
          delivery_id: body.delivery_id,
          delivery_service_options: body.delivery_service_options,
          delivery_special_instructions: body.delivery_special_instructions,
          delivery_state: body.delivery_state,
          delivery_time: body.delivery_time,
          delivery_zip_code: body.delivery_zip_code,
          delivery_to: body.delivery_to,
          is_delivery_flexible: body.is_delivery_flexible,
          is_delivery_time_flexible: body.is_delivery_time_flexible,
          deliveryLocation: body.deliveryLocation ?? null
        };

        finalBody = {
          ...finalBody,
          pickup_close_time: body.pickup_close_time,
          pickup_date: body.pickup_date,
          pickup_formatted_address: body.pickup_formatted_address,
          pickup_from: body.pickup_from,
          pickup_id: body.pickup_id,
          pickup_service_options: body.pickup_service_options,
          pickup_special_instructions: body.pickup_special_instructions,
          pickup_state: body.pickup_state,
          pickup_time: body.pickup_time,
          pickup_zip_code: body.pickup_zip_code,
          is_pickup_flexible: body.is_pickup_flexible,
          is_pickup_time_flexible: body.is_pickup_time_flexible,
          pickupLocation: body.pickupLocation ?? null
        };

        if (
          form?.pickup_address &&
          quoteDataValue?.pickup_formatted_address ===
            finalBody?.pickup_formatted_address
        ) {
          finalBody.pickup_address = form?.pickup_address;
        }

        if (
          form?.delivery_address &&
          quoteDataValue?.delivery_formatted_address ===
            finalBody?.delivery_formatted_address
        ) {
          finalBody.delivery_address = form?.delivery_address;
        }

        finalBody.service_options_type = body.service_options_type;
      }

      const response = await addQuoteService(finalBody);

      if (response?.data?.flag) {
        toast.success(response?.data?.message);

        if (response?.data?.quote?.bidType === QUOTE_FROM_NET) {
          navigate(`/new-quote-summary/${response?.data?.quote?.id}`);
        } else if (response?.data?.quote?.bidType === FIXED_AMOUNT) {
          navigate(`/book-now-quote/${response?.data?.quote?.id}`);
        } else if (response?.data?.quote?.bidType === OPEN_BID) {
          navigate(`/shipper-thank-you`);
        } else if (!response?.data?.quote?.estimate) {
          navigate(`/thank-you`);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    setserviceType(serviceTypeVal);
  }, [serviceTypeVal]);

  async function fetchQuoteDetails() {
    try {
      const quoteId = isDuplicate();
      if (quoteId) {
        const response = await getQuoteDetailsService(quoteId);
        if (!response?.data?.quote?.isMultipleQuote) {
          setQuickQuoteForm(response?.data?.quote);
        }
        setQuoteDataValue(response?.data?.quote);
        const quoteData = response?.data?.quote;

        const isPickupAddress1Existing = quoteData?.pickup_from
          ?.split('|')?.[0]
          .trim().length;

        const isDeliveryAddress1Existing = quoteData?.delivery_to
          ?.split('|')?.[0]
          .trim().length;

        const updatePickupData = {
          pickup_id: quoteData?.pickup_id,
          pickup_date: '',
          pickup_time: '',
          pickup_close_time: '',
          pickup_from: quoteData?.pickup_from,
          pickup_formatted_address:
            isPickupAddress1Existing || !quoteData?.booked
              ? quoteData?.pickup_formatted_address
              : `${quoteData?.pickup_address?.address1}${
                  quoteData?.pickup_address?.address2.trim()
                    ? `, ${quoteData?.pickup_address?.address2}`
                    : ''
                }${
                  quoteData?.pickup_formatted_address
                    ? `, ${quoteData?.pickup_formatted_address}`
                    : ''
                }`,
          pickup_zip_code: quoteData?.pickup_zip_code,
          is_pickup_flexible: true,
          is_pickup_time_flexible: true,
          pickup_special_instructions: quoteData?.pickup_special_instructions,
          pickup_service_options: quoteData?.pickup_service_options.map(
            (service) => service.id
          ),
          pickupLocation: quoteData?.pickupLocation,
          pickup_address: quoteData?.pickup_address
        };
        const updateDeliveryData = {
          delivery_id: quoteData?.delivery_id,
          delivery_date: '',
          delivery_time: '',
          delivery_close_time: '',
          delivery_to: quoteData?.delivery_to,
          delivery_formatted_address:
            isDeliveryAddress1Existing || !quoteData?.booked
              ? quoteData?.delivery_formatted_address
              : `${quoteData?.delivery_address?.address1}${quoteData?.delivery_address?.address2.trim() ? `, ${quoteData?.delivery_address?.address2}` : ''}${quoteData?.delivery_formatted_address ? `, ${quoteData?.delivery_formatted_address}` : ''}`,
          delivery_zip_code: quoteData?.delivery_zip_code,
          is_delivery_flexible: true,
          is_delivery_time_flexible: true,
          delivery_special_instructions:
            quoteData?.delivery_special_instructions,
          delivery_service_options: quoteData?.delivery_service_options.map(
            (service) => service.id
          ),
          deliveryLocation: quoteData?.deliveryLocation,
          delivery_address: quoteData?.delivery_address
        };
        const updatedData = {
          ...response.data.quote,
          ...updatePickupData,
          ...updateDeliveryData
        };

        if (updatedData?.equipmentType?.length) {
          updatedData.equipmentType = updatedData.equipmentType?.map((eq) => {
            return EquipmentTypes?.find((e) => e.value === eq);
          });
        }

        if (
          ![LTL_FREIGHT, BLANKET_WRAP].includes(updatedData.service_type) &&
          !quoteData?.isMultipleQuote
        ) {
          updatedData.pickupInfo = [updatePickupData];
          updatedData.deliveryInfo = [updateDeliveryData];
        }
        if (updatedData.isMultipleQuote) {
          const updatedPickupInfo = updatedData.pickupInfo.map((pick) => {
            const isPickAddress1Existing = pick?.pickup_from
              ?.split('|')?.[0]
              .trim().length;

            const pickCopy = { ...pick };
            pickCopy.pickup_id = pick.pickup_id ?? pick.id;
            pickCopy.pickup_date = '';
            pickCopy.pickup_time = '';
            pickCopy.pickup_close_time = '';
            pickCopy.is_pickup_flexible = true;
            pickCopy.is_pickup_time_flexible = true;
            pickCopy.pickup_formatted_address =
              isPickAddress1Existing || !quoteData?.booked
                ? pickCopy?.pickup_formatted_address
                : `${pickCopy?.pickup_address?.address1}${pickCopy?.pickup_address?.address2.trim() ? `, ${pickCopy?.pickup_address?.address2}` : ''}${pickCopy?.pickup_formatted_address ? `, ${pickCopy?.pickup_formatted_address}` : ''}`;
            return pickCopy;
          });
          const updatedDeliveryInfo = updatedData.deliveryInfo.map((del) => {
            const isDelAddress1Existing = del?.delivery_to
              ?.split('|')?.[0]
              .trim().length;

            const delCopy = { ...del };
            delCopy.delivery_id = del.delivery_id ?? del.id;
            delCopy.delivery_date = '';
            delCopy.delivery_time = '';
            delCopy.delivery_close_time = '';
            delCopy.is_delivery_flexible = true;
            delCopy.is_delivery_time_flexible = true;
            delCopy.delivery_formatted_address =
              isDelAddress1Existing || !quoteData?.booked
                ? delCopy?.delivery_formatted_address
                : `${delCopy?.delivery_address?.address1}${delCopy?.delivery_address?.address2.trim() ? `, ${delCopy?.delivery_address?.address2}` : ''}${delCopy?.delivery_formatted_address ? `, ${delCopy?.delivery_formatted_address}` : ''}`;
            return delCopy;
          });
          updatedData.pickupInfo = updatedPickupInfo;
          updatedData.deliveryInfo = updatedDeliveryInfo;
        }
        const updatedCargo = quoteData?.cargos.map((cargo) => {
          return {
            ...cargo,
            package_type: cargo?.package_type?.[0].id,
            goods_type: cargo?.goods_type?.map((type) => type.id),
            goods_condition: {
              value: cargo?.goods_condition?.id,
              label:
                cargo?.goods_condition?.display_name ||
                cargo?.goods_condition?.label
            },
            freight_class: {
              value: cargo?.freight_class,
              label: cargo?.freight_class
            },
            cargo_img: cargo?.files?.fd
              ? `${process.env.REACT_APP_API_URL}/${cargo?.files?.fd}`
              : '',
            files: cargo?.files?.id
          };
        });
        updatedData.bidType = null;
        updatedData.cargos = updatedCargo;
        quoteData?.service_options_type?.forEach((serviceOptionType) => {
          if (
            serviceOptionType.option_type === 'pickup' ||
            serviceOptionType.option_type === undefined
          ) {
            updatedData.pickup_limited_access_type = {
              value: serviceOptionType.id,
              label: serviceOptionType.display_name
            };
          }
          if (serviceOptionType.option_type === 'delivery') {
            if (
              serviceOptionType.id === '636a104aeb0cee8407829bc8' ||
              serviceOptionType.id === '636a104aeb0cee8407829bc9'
            ) {
              updatedData.delivery_tradeshow_type = {
                value: serviceOptionType.id,
                label: serviceOptionType.display_name
              };
            } else {
              updatedData.delivery_limited_access_type = {
                value: serviceOptionType.id,
                label: serviceOptionType.display_name
              };
            }
          }
        });
        reset(updatedData);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    const link = localStorage.getItem('link');
    if (link) {
      localStorage.removeItem('link');
      return navigate(link);
    }
    fetchQuoteDetails();
    return () => {
      if (!quoteDataValue?.isMultipleQuote) {
        clearQuickQuoteForm();
      }
    };
  }, []);

  useEffect(() => {
    if (quoteDataValue?.isMultipleQuote) {
      setIsQuickQuoteEnabled(false);
    } else {
      setIsQuickQuoteEnabled(true);
    }
    setViewType(VIEW_TYPE.GUIDED_VIEW);
  }, [quoteDataValue]);

  return (
    <div className="">
      <section className="py-20">
        {viewType === QUICK_VIEW ? (
          <QuickQuotePage />
        ) : (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
              {step === buttonLabels.length && (
                <div className="container px-4 mx-auto">
                  <div className="flex flex-wrap h-full -mx-4 text-center">
                    <div className="w-full px-3 mb-8 lg:w-1/2">
                      {GetStepContent(compiledForm)}
                      <div className="relative p-4 pl-0 text-left">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          onClick={nextStep}
                          className="order-1 inline-block w-full py-4 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                        >
                          {buttonLabels[step - 1]}
                          <RightArrowIcon className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-6 mr-4 font-bold pointer-events-none" />
                        </button>
                      </div>
                    </div>
                    <RenderGoogleMap
                      pickupZipCode={
                        form?.pickupInfo?.length
                          ? form?.pickupInfo?.map(
                              (pick) =>
                                pick?.pickup_formatted_address ||
                                pick.pickup_zip_code
                            )
                          : [
                              form?.pickup_formatted_address ||
                                form.pickup_zip_code
                            ]
                      }
                      deliveryZipCode={
                        form?.deliveryInfo?.length
                          ? form?.deliveryInfo?.map(
                              (del) =>
                                del?.delivery_formatted_address ||
                                del.delivery_zip_code
                            )
                          : [
                              form?.delivery_formatted_address ||
                                form.delivery_zip_code
                            ]
                      }
                      isZipError={(status) => setZipError(status)}
                    />
                  </div>
                </div>
              )}
              {step === 4 && GetStepContent(compiledForm)}
              {step !== buttonLabels.length && step !== 4 && (
                <div className="container mx-auto">
                  <div className="mx-auto">
                    <div className="mb-6 text-left lg:mb-10">
                      {GetStepContent(compiledForm)}
                      {[LTL_FREIGHT, BLANKET_WRAP]?.includes(
                        compiledForm?.one?.service_type
                      ) &&
                        step !== 1 && (
                          <div className="relative p-4 text-left">
                            <button
                              type="button"
                              onClick={nextStep}
                              className="order-1 inline-block w-full py-4 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                            >
                              {buttonLabels[step - 1]}
                              <RightArrowIcon className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-6 mr-4 font-bold pointer-events-none" />
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        )}
      </section>
    </div>
  );
};

export default NewQuotePage;
