import React, { createContext, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import Roles from '../constants/roles.constants';
import { isDuplicate, VIEW_TYPE } from '../constants/generalConstants';
import {
  DELIVERY_LIMITED_ACCESS_TYPES,
  DELIVERY_SERVICE_OPTION,
  DELIVERY_TRADESHOW_TYPES,
  FREIGHT_CLASS,
  PACKAGE_TYPE,
  PICKUP_LIMITED_ACCESS_TYPES,
  PICKUP_SERVICE_OPTION
} from '../constants/quote.constants';
import {
  BLANKET_WRAP,
  DELIVERY_LIMITED_ACCESS,
  DELIVERY_TRADE_SHOW,
  LTL_FREIGHT,
  PICKUP_LIMITED_ACCESS
} from '../constants/service_type.constants';
import { useAuthContext } from './auth.context';
import { addQuoteService } from '../services/quote.service';

const GlobalFormContext = createContext();

const GlobalFormProvider = ({ children }) => {
  const { authUser } = useAuthContext();
  const navigate = useNavigate();

  const pickupRef = useRef(null);
  const deliveryRef = useRef(null);
  const lengthRef = useRef(null);
  const customerRef = useRef(null);
  const responsibleUserRef = useRef(null);

  const [validate, setValidate] = useState(false);
  const [viewType, setViewType] = useState(VIEW_TYPE.GUIDED_VIEW);
  const [serviceType, setServiceType] = useState('');
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [responsibleUser, setResponsibleUser] = useState({});
  const [pickupServices, setPickupServices] = useState([
    PICKUP_SERVICE_OPTION[0].value
  ]);
  const [deliveryServices, setDeliveryServices] = useState([
    DELIVERY_SERVICE_OPTION[0].value
  ]);
  const [cargos, setCargos] = useState([]);
  const [pickupAddress, setPickupAddress] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [isQuickQuoteEnabled, setIsQuickQuoteEnabled] = useState(true);
  const [serviceOptionsType, setServiceOptionsType] = useState([]);
  const [cargoValidate, setCargoValidate] = useState(false);
  const [cargoType, setCargotype] = useState(PACKAGE_TYPE[0]);
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');
  const [description, setDescription] = useState('');
  const [isDimensionMandatory, setIsDimensionMandatory] = useState(false);
  const [freightClass, setFreightClass] = useState('');
  const [nmfcCode, setNmfcCode] = useState('');
  const [quote, setQuote] = useState({});
  const clearCargoForm = () => {
    setCargotype(PACKAGE_TYPE[0]);
    setLength('');
    setWidth('');
    setHeight('');
    setWeight('');
    setDescription('');
    setCargoValidate(false);
    if (serviceType === LTL_FREIGHT) {
      setFreightClass('');
      setNmfcCode('');
    }
  };

  const clearQuickQuoteForm = () => {
    setValidate(false);
    setCurrentCustomer({});
    setResponsibleUser({});
    setPickupServices([PICKUP_SERVICE_OPTION[0].value]);
    setDeliveryServices([DELIVERY_SERVICE_OPTION[0].value]);
    setCargos([]);
    setPickupAddress({});
    setDeliveryAddress({});
    setIsQuickQuoteEnabled(true);
    setServiceOptionsType([]);
    clearCargoForm();
    setServiceType('');
  };

  const allCargoFieldsEmpty = () => {
    if (!length && !width && !height && !weight && !description) return true;
    return false;
  };

  const handleAddCargo = (getQuoteClicked) => {
    if (getQuoteClicked && allCargoFieldsEmpty() && cargos.length) {
      return cargos;
    }
    if (
      !cargoValidate ||
      !weight ||
      !description ||
      (isDimensionMandatory && (!length || !width || !height)) ||
      (serviceType === LTL_FREIGHT && !freightClass)
    ) {
      setCargoValidate(true);

      if (!description || !weight) {
        return;
      }
      if (isDimensionMandatory && (!length || !width || !height)) {
        return;
      }
      if (
        serviceType === LTL_FREIGHT &&
        (!freightClass || freightClass?.value === '0')
      )
        return;
    }

    let currentCargo;

    if (!isDuplicate()) {
      currentCargo = {
        id: uuidv4(),
        cargoType,
        length,
        width,
        height,
        weight,
        description
      };
      if (serviceType === LTL_FREIGHT) {
        currentCargo.freightClass = freightClass;
        currentCargo.nmfcCode = nmfcCode;
      }
    } else {
      const existingCargo = cargos[0];
      currentCargo = {
        ...existingCargo,
        id: uuidv4(),
        cargoType,
        length,
        width,
        height,
        weight,
        desc: description
      };
      if (serviceType === LTL_FREIGHT) {
        currentCargo.freightClass = FREIGHT_CLASS.find(
          (freight) => freight?.value === existingCargo?.freight_class
        );
        currentCargo.nmfcCode = existingCargo?.nmfc_code;
      }
    }

    if (serviceType === LTL_FREIGHT) {
      currentCargo.freight_class = freightClass?.value;
    }

    const updatedCargos = [...cargos, currentCargo];
    setCargos(updatedCargos);
    clearCargoForm();
    return updatedCargos;
  };

  const prefillCustomerSection = (fetchedQuote) => {
    const customer = {
      id: fetchedQuote?.company?.id,
      value: fetchedQuote?.company?.id,
      label: fetchedQuote?.company?.name,
      creditLimit: fetchedQuote?.company?.creditLimit,
      paymentTerms: fetchedQuote?.company?.paymentTerms,
      openBalance: fetchedQuote?.company?.openBalance
    };
    setCurrentCustomer(customer);
  };

  const prefillPickupAddress = (fetchedQuote) => {
    const pickupLocationData = {
      pickup_from: fetchedQuote?.pickup_from,
      pickup_formatted_address: fetchedQuote?.pickup_formatted_address,
      pickup_zip_code: fetchedQuote?.pickup_zip_code,
      pickup_state: fetchedQuote?.pickup_state,
      pickup_id: fetchedQuote?.pickup_id ?? uuidv4(),
      pickup_date: 'flexible date',
      is_pickup_flexible: true,
      is_pickup_time_flexible: true,
      pickup_time: 'flexible time',
      pickup_close_time: '',
      pickup_special_instructions: fetchedQuote?.pickup_special_instructions,
      pickupLocation: fetchedQuote?.pickupLocation?.id ?? null
    };

    if (fetchedQuote?.pickup_address) {
      pickupLocationData.pickup_address = fetchedQuote?.pickup_address;
    }

    const pickupServicesArray = fetchedQuote?.pickup_service_options?.map(
      (item) => item.id
    );
    setPickupServices(pickupServicesArray);
    setPickupAddress(pickupLocationData);
  };

  const prefillDeliveryAddress = (fetchedQuote) => {
    const deliveryLocationData = {
      delivery_to: fetchedQuote?.delivery_to,
      delivery_formatted_address: fetchedQuote?.delivery_formatted_address,
      delivery_zip_code: fetchedQuote?.delivery_zip_code,
      delivery_state: fetchedQuote?.delivery_state,
      delivery_id: fetchedQuote?.delivery_id ?? uuidv4(),
      delivery_date: 'flexible date',
      is_delivery_flexible: true,
      is_delivery_time_flexible: true,
      delivery_time: 'flexible time',
      delivery_close_time: '',
      delivery_special_instructions:
        fetchedQuote?.delivery_special_instructions,
      deliveryLocation: fetchedQuote?.deliveryLocation?.id ?? null
    };

    if (fetchedQuote?.delivery_address) {
      deliveryLocationData.delivery_address = fetchedQuote?.delivery_address;
    }

    const deliveryServicesArray = fetchedQuote?.delivery_service_options?.map(
      (item) => item.id
    );
    setDeliveryServices(deliveryServicesArray);
    setDeliveryAddress(deliveryLocationData);
  };

  const prefillCargos = (fetchedQuote) => {
    const updatedDefaultCargos = fetchedQuote?.cargos?.map((item) => {
      const updatedCg = {
        ...item,
        cargoType: PACKAGE_TYPE.find(
          (pk) => pk.value === item?.package_type[0]?.id
        ),
        description: item?.desc,
        nmfcCode: item?.nmfc_code,
        freightClass: FREIGHT_CLASS.find(
          (freight) => freight.value === item?.freight_class
        )
      };
      return updatedCg;
    });
    setCargos(updatedDefaultCargos);
  };

  const setQuickQuoteForm = (fetchedQuote) => {
    clearQuickQuoteForm();
    setQuote(fetchedQuote);

    // for customers section
    prefillCustomerSection(fetchedQuote);

    // for service type section
    setServiceType(fetchedQuote?.service_type);

    // pickup address
    prefillPickupAddress(fetchedQuote);

    // delivery address
    prefillDeliveryAddress(fetchedQuote);

    if (fetchedQuote?.service_options_type?.length > 0) {
      const serviceOptions = fetchedQuote?.service_options_type?.map(
        (item) => item?.id
      );
      setServiceOptionsType(serviceOptions);
    }

    // set cargo
    prefillCargos(fetchedQuote);
  };

  const isPickupLimitedAccessInvalid = () => {
    if (
      serviceType &&
      [LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) &&
      pickupServices.includes(PICKUP_LIMITED_ACCESS)
    ) {
      return (
        _.intersection(
          serviceOptionsType,
          PICKUP_LIMITED_ACCESS_TYPES.map((item) => item.value)
        ).length === 0
      );
    }
    return false;
  };
  const isDeliveryLimitedAccessInvalid = () => {
    if (
      serviceType &&
      [LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) &&
      deliveryServices.includes(DELIVERY_LIMITED_ACCESS)
    ) {
      return (
        _.intersection(
          serviceOptionsType,
          DELIVERY_LIMITED_ACCESS_TYPES.map((item) => item.value)
        ).length === 0
      );
    }
    return false;
  };
  const isDeliveryTradeshowInvalid = () => {
    if (
      serviceType &&
      [LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) &&
      deliveryServices.includes(DELIVERY_TRADE_SHOW)
    ) {
      return (
        _.intersection(
          serviceOptionsType,
          DELIVERY_TRADESHOW_TYPES.map((item) => item.value)
        ).length === 0
      );
    }
    return false;
  };

  const isQuickQuoteFormInvalid = () => {
    if (
      !Object.keys(currentCustomer).length ||
      (authUser.user.usertype !== Roles.SHIPPER &&
        !Object.keys(responsibleUser ?? {}).length) ||
      !serviceType ||
      !pickupAddress.pickup_id ||
      !deliveryAddress.delivery_id ||
      (!cargos.length && allCargoFieldsEmpty()) ||
      isPickupLimitedAccessInvalid() ||
      isDeliveryLimitedAccessInvalid() ||
      isDeliveryTradeshowInvalid()
    ) {
      // for debugging purpose
      const isCarfoFormEmpty = allCargoFieldsEmpty();
      const ispicklimitInvalid = isPickupLimitedAccessInvalid();
      const isdellimitInvalid = isDeliveryLimitedAccessInvalid();
      const isdeltradeInvalid = isDeliveryTradeshowInvalid();
      console.log({
        currentCustomer,
        responsibleUser,
        serviceType,
        pickupAddress,
        deliveryAddress,
        cargos,
        isCarfoFormEmpty,
        ispicklimitInvalid,
        isdellimitInvalid,
        isdeltradeInvalid
      });
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (isQuickQuoteFormInvalid()) {
      setValidate(true);
      toast.error('Please fill mandatory fields!');
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }
    const newCargos = handleAddCargo(true);

    if (!newCargos) return;

    let updatedCargos;

    if (!isDuplicate()) {
      updatedCargos = newCargos.map((cg) => {
        const updatedCg = {};
        updatedCg.quantity = 1;
        updatedCg.package_type = cg?.cargoType?.value;
        updatedCg.goods_condition = '57f638ef95757c64129caea6';
        updatedCg.freight_class = freightClass?.value;
        updatedCg.nmfc_code = nmfcCode;
        updatedCg.height = cg.height;
        updatedCg.width = cg.width;
        updatedCg.length = cg.length;
        updatedCg.weight = cg.weight;
        updatedCg.desc = cg.description;
        updatedCg.tarps = false;
        updatedCg.goods_type = [];
        updatedCg.ship_from = pickupAddress?.pickup_id;
        updatedCg.ship_to = deliveryAddress?.delivery_id;
        updatedCg.version = 'v3';
        updatedCg.files = '614c7a036041a608020740b0';

        if (serviceType === LTL_FREIGHT) {
          updatedCg.freight_class = cg.freight_class;
        }

        return updatedCg;
      });
    } else {
      updatedCargos = newCargos.map((cg) => {
        const updatedCg = {};
        updatedCg.quantity = cg?.quantity;
        updatedCg.package_type = cg?.cargoType?.value;
        updatedCg.goods_condition = cg?.goods_condition?.id;
        updatedCg.freight_class = cg?.freight_class;
        updatedCg.nmfc_code = cg?.nmfc_code;
        updatedCg.height = cg?.height;
        updatedCg.width = cg?.width;
        updatedCg.length = cg?.length;
        updatedCg.weight = cg?.weight;
        updatedCg.desc = cg?.desc;
        updatedCg.tarps = cg?.tarps;
        updatedCg.goods_type = cg?.goods_type?.map((item) => item?.id);
        updatedCg.ship_from = pickupAddress?.pickup_id;
        updatedCg.ship_to = deliveryAddress?.delivery_id;
        updatedCg.version = 'v3';
        updatedCg.files = '614c7a036041a608020740b0';

        if (serviceType === LTL_FREIGHT) {
          updatedCg.freight_class = cg.freight_class;
        }

        return updatedCg;
      });
    }

    const body = {
      service_type: serviceType,
      ...pickupAddress,
      ...deliveryAddress,
      service_options_type: serviceOptionsType,
      is_insurance_required: false,
      insurance_value: '',
      bidType: 1,
      company: currentCustomer?.id,
      responsibleUser:
        authUser.user.usertype === Roles.SHIPPER
          ? authUser?.user?.id
          : responsibleUser?.id,
      isMultipleQuote: false,
      cargos: updatedCargos
    };

    try {
      const response = await addQuoteService(body);
      if (response.data.flag) {
        toast.success(response.data.message);

        // since bid type will always be Quote from NET in quick quote i.e 1
        navigate(`/new-quote-summary/${response.data.quote.id}`);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
    clearQuickQuoteForm();
  };

  const viewTypeContextValue = useMemo(
    () => ({
      validate,
      viewType,
      setViewType,
      serviceType,
      setServiceType,
      currentCustomer,
      setCurrentCustomer,
      pickupServices,
      setPickupServices,
      deliveryServices,
      setDeliveryServices,
      cargos,
      setCargos,
      pickupAddress,
      setPickupAddress,
      deliveryAddress,
      setDeliveryAddress,
      responsibleUser,
      setResponsibleUser,
      isQuickQuoteEnabled,
      setIsQuickQuoteEnabled,
      serviceOptionsType,
      setServiceOptionsType,
      handleAddCargo,
      cargoValidate,
      setCargoValidate,
      cargoType,
      setCargotype,
      length,
      setLength,
      width,
      setWidth,
      height,
      setHeight,
      weight,
      setWeight,
      description,
      setDescription,
      isDimensionMandatory,
      setIsDimensionMandatory,
      clearCargoForm,
      handleSubmit,
      allCargoFieldsEmpty,
      isPickupLimitedAccessInvalid,
      isDeliveryLimitedAccessInvalid,
      isDeliveryTradeshowInvalid,
      setQuickQuoteForm,
      quote,
      clearQuickQuoteForm,
      freightClass,
      setFreightClass,
      nmfcCode,
      setNmfcCode,
      pickupRef,
      deliveryRef,
      lengthRef,
      customerRef,
      responsibleUserRef
    }),
    [
      viewType,
      serviceType,
      validate,
      currentCustomer,
      pickupServices,
      deliveryServices,
      cargos,
      pickupAddress,
      deliveryAddress,
      responsibleUser,
      isQuickQuoteEnabled,
      serviceOptionsType,
      cargoValidate,
      cargoType,
      length,
      width,
      height,
      weight,
      description,
      isDimensionMandatory,
      quote,
      freightClass,
      nmfcCode,
      pickupRef,
      deliveryRef,
      lengthRef,
      customerRef,
      responsibleUserRef
    ]
  );

  return (
    <GlobalFormContext.Provider value={viewTypeContextValue}>
      {children}
    </GlobalFormContext.Provider>
  );
};

export { GlobalFormContext, GlobalFormProvider };

GlobalFormProvider.propTypes = {
  children: PropTypes.any.isRequired
};
