/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import CargoDetailsCard from '../CargoDetailsCard/CargoDetailsCard';
import {
  BLANKET_WRAP,
  LTL_FREIGHT,
  TRUCKLOAD_FLAT,
  TRUCKLOAD_VAN
} from '../../constants/service_type.constants';
import {
  FREIGHT_CLASS,
  DELIVERY_SERVICE_OPTION,
  PICKUP_SERVICE_OPTION,
  SERVICE_TYPES,
  GOODS_TYPE,
  PACKAGE_TYPE,
  GOODS_CONDITION,
  ADDITIONAL_FEES
} from '../../constants/quote.constants';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import { updateShipmentService } from '../../services/quote.service';
import { VENDORS } from '../../constants/carrier.constants';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import TimePickerInput from '../TimePickerInput/TimePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { getCityStateNames } from '../../services/geo.service';
import AddNewCargo from '../../pages/Shipper/Profile/AddNewCargo';
import {
  additionalCharges,
  getInsuranceMarkup,
  getQuotePrice
} from '../../utils/quote.util';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';
import AddFeesPopup from '../AddFeesPopup';
import { useIsInvoiceAuthorizedUser } from '../../constants/invoice.constants';

const UpdateShipment = memo(({ quote, fetchQuoteDetails }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const [validation, setValidation] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [addFees, setAddFees] = useState('');

  const insuranceMarkup = getInsuranceMarkup(
    quote.is_insurance_required,
    quote.insurance_value
  );

  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const {
    PENDING_QUOTE,
    SALES_REP_PENDING_QUOTE,
    ENROUTE_QUOTE,
    DELIVERED_QUOTE
  } = BID_STATUS;

  const isInvoiceAuthorizedUser = useIsInvoiceAuthorizedUser();

  const validationSchema = yup.object({
    service_type: yup.mixed().required('Please select a Service Type.'),
    pickup_zip_code: yup.string().required('Zip code is required'),
    pickup_date: yup.string().when('is_pickup_flexible', {
      is: false,
      then: () => yup.string().required('Pick up date is required')
    }),
    pickup_time: yup.string().when('is_pickup_time_flexible', {
      is: false,
      then: () => yup.string().required('Pick up time is required')
    }),
    pickup_close_time: yup.string().required('Pickup close time is required'),
    pickup_service_options: yup.mixed().nullable(),
    pickup_special_instructions: yup.string(),
    delivery_zip_code: yup.string().required('Zip code is required'),
    delivery_date: yup.string().when('is_delivery_flexible', {
      is: false,
      then: () => yup.string().required('Delivery date is required')
    }),
    delivery_time: yup.string().when('is_delivery_time_flexible', {
      is: false,
      then: () => yup.string().required('Delivery time is required')
    }),
    delivery_close_time: yup
      .string()
      .required('Delivery close time is required'),
    delivery_service_options: yup.array().nullable(),
    delivery_special_instructions: yup.string(),
    cargos: yup.array().of(
      yup.object({
        goods_condition: yup.mixed().required('Goods Condition is required'),
        freight_class: yup.mixed().when('dummy', {
          is: () => quote.service_type === LTL_FREIGHT,
          then: () => yup.mixed().required('Freight Class is required'),
          otherwise: () => yup.mixed().nullable()
        }),
        nmfc_code: yup.mixed().when('dummy', {
          is: () => quote.service_type === LTL_FREIGHT,
          then: () => yup.mixed().nullable(),
          otherwise: () => yup.mixed().nullable()
        }),
        goods_type: yup.array().nullable(),
        package_type: yup.mixed().required('Cargo Package type is required'),
        width: yup.number().when('dummy', {
          is: () =>
            quote.service_type === TRUCKLOAD_FLAT ||
            quote.service_type === TRUCKLOAD_VAN,
          then: () =>
            yup
              .number()
              .positive('Width must be a positive number')
              .min(0)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          otherwise: () =>
            yup
              .number()
              .positive('Width must be a positive number')
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo width is required')
        }),
        length: yup.number().when('dummy', {
          is: () =>
            quote.service_type === TRUCKLOAD_FLAT ||
            quote.service_type === TRUCKLOAD_VAN,
          then: () =>
            yup
              .number()
              .positive('Length must be a positive number')
              .min(0)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          otherwise: () =>
            yup
              .number()
              .positive('Length must be a positive number')
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo length is required')
        }),
        height: yup.number().when('dummy', {
          is: () =>
            quote.service_type === TRUCKLOAD_FLAT ||
            quote.service_type === TRUCKLOAD_VAN,
          then: () =>
            yup
              .number()
              .positive('Height must be a positive number')
              .min(0)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          otherwise: () =>
            yup
              .number()
              .positive('Height must be a positive number')
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo height is required')
        }),
        weight: yup
          .number()
          .positive('Weight must be a positive number')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo weight is required'),
        desc: yup.string().required('Cargo description is required'),
        quantity: yup
          .number()
          .positive('Quanitity must be positive')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo quantity is required')
      })
    ),
    special_instructions: yup.string().nullable(),
    trackingNumber: yup.string().nullable(),
    xpo: yup
      .object()
      .nullable()
      .shape({
        xpo_estimate: yup
          .number()
          .min(0, 'Price must be a non-negative number')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable(),
        commission: yup
          .number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable()
      }),
    abf: yup
      .object()
      .nullable()
      .shape({
        abf_estimate: yup
          .number()
          .min(0, 'Price must be a non-negative number')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable(),
        commission: yup
          .number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable()
      }),

    forwardair: yup
      .object()
      .nullable()
      .shape({
        forwardair_estimate: yup
          .number()
          .min(0, 'Price must be a non-negative number')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable(),
        commission: yup
          .number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable()
      }),
    fedex: yup
      .object()
      .nullable()
      .shape({
        fedex_estimate: yup
          .number()
          .min(0, 'Price must be a non-negative number')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable(),
        commission: yup
          .number()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .nullable()
      }),
    bidType: yup.number(),
    pickup_address: yup.object({
      company: yup.string().required('Company name is required'),
      address1: yup.string().when('dummy', {
        is: () => quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID,
        then: () => yup.string().nullable(),
        otherwise: () => yup.string().required('Pickup address1 is required')
      }),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      country: yup.string().required('Country is required').default('US'),
      zip: yup.string().required('Zip code is required'),
      contactperson: yup.string(),
      contactnumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Mobile number must be only digits')
        .required('Mobile number is required')
        .max(11)
        .min(10, 'Mobile number must be at least 10 digits')
    }),
    delivery_address: yup.object({
      company: yup.string().required('Company name is required'),
      address1: yup.string().when('dummy', {
        is: () => quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID,
        then: () => yup.string().nullable(),
        otherwise: () => yup.string().required('Delivery address1 is required')
      }),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      country: yup.string().required('Country is required').default('US'),
      zip: yup.string().required('Zip code is required'),
      contactperson: yup.string(),
      contactnumber: yup
        .string()
        .matches(/^[0-9]+$/, 'Mobile number must be only digits')
        .required('Mobile number is required')
        .max(11)
        .min(10, 'Mobile number must be at least 10 digits')
    }),
    estimate: yup.mixed().when('dummy', {
      is: () => quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID,
      then: () => yup.number().nullable(),
      otherwise: () =>
        yup.number().positive().required('Estimate should be empty.')
    }),
    commission: yup.number().nullable(),
    netCharge: yup.number().nullable(),
    driverName: yup.string().nullable(),
    driverPhone: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/^[0-9]+$/, 'Mobile number must be only digits')
      .max(11)
      .min(10, 'Mobile number must be at least 10 digits')
  });

  const opt2 = yup.object({
    amount: yup.number().required(),
    netCharge: yup.number().required()
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(validation)
  });
  const form = watch();
  const currentServiceType = watch('service_type');

  const onSubmit = async (data) => {
    const updatedData = { ...data };
    updatedData.pickup_date = !updatedData.pickup_date
      ? 'flexible date'
      : moment(data.pickup_date).format('MM/DD/YYYY');
    updatedData.pickup_time = !updatedData.pickup_time
      ? 'flexible time'
      : moment(data.pickup_time).format('h:mm A');
    updatedData.pickup_close_time = !updatedData.pickup_close_time
      ? ''
      : moment(data.pickup_close_time).format('h:mm A');
    updatedData.delivery_date = !updatedData.delivery_date
      ? 'flexible date'
      : moment(data.delivery_date).format('MM/DD/YYYY');
    updatedData.delivery_time = !updatedData.delivery_time
      ? 'flexible time'
      : moment(data.delivery_time).format('h:mm A');
    updatedData.delivery_close_time = !updatedData.delivery_close_time
      ? ''
      : moment(data.delivery_close_time).format('h:mm A');

    const { revenueCharge } = additionalCharges(quote);
    const { carrierCharge } = additionalCharges(quote);

    if (data.service_type === LTL_FREIGHT) {
      updatedData[quote.carrier_type] = {
        ...updatedData[quote.carrier_type],
        estimate:
          Number(updatedData[quote.carrier_type]?.estimate) - revenueCharge,
        [`${quote.carrier_type}_estimate`]:
          Number(
            updatedData[quote.carrier_type]?.[`${quote.carrier_type}_estimate`]
          ) - carrierCharge,
        commission:
          Number(updatedData[quote.carrier_type]?.commission) -
          (revenueCharge - carrierCharge)
      };
      updatedData.estimate = data?.carrier_type
        ? data[`${data?.carrier_type}`][`${data?.carrier_type}_estimate`] +
          data[`${data?.carrier_type}`].commission
        : data?.estimate;
    }
    delete updatedData.carrierPrice;

    if (updatedData.amount) {
      updatedData.amount =
        updatedData.bidType === FIXED_AMOUNT
          ? Number(updatedData.amount) - Number(updatedData.tarps_value)
          : Number(updatedData.amount) -
            Number(updatedData.netCharge) -
            Number(updatedData.tarps_value);
      updatedData.amount -= revenueCharge;
    }

    if (updatedData.estimate && updatedData.bidType === QUOTE_FROM_NET) {
      updatedData.estimate -= updatedData.tarps_value;
      updatedData.estimate -= revenueCharge;
    }
    let differ = Object.keys(updatedData).reduce((diff, key) => {
      const updatedCargo = quote.cargos.map((cargo) => {
        return {
          ...cargo,
          package_type: cargo?.package_type?.[0].id,
          goods_type: cargo?.goods_type?.map((type) => type.id),
          goods_condition: cargo?.goods_condition?.id
        };
      });
      const quoteData = {
        ...quote,

        cargos: updatedCargo,
        delivery_service_options: quote.delivery_service_options.map(
          (service) => service.id
        ),
        pickup_service_options: quote.pickup_service_options.map(
          (service) => service.id
        )
      };
      if (JSON.stringify(quoteData[key]) === JSON.stringify(updatedData[key]))
        return diff;
      return {
        ...diff,
        [key]: updatedData[key]
      };
    }, {});

    if (!Object.keys(differ).length)
      return toast.error('You have not made any changes!');

    differ.id = quote.id;
    differ.company = quote?.company?.id;
    if (differ.pickup_zip_code) {
      try {
        const pickupResponse = await getCityStateNames(differ.pickup_zip_code);
        differ.pickup_address = {
          ...data.pickup_address,
          city: pickupResponse.cityName,
          state: pickupResponse.stateName,
          zip: differ.pickup_zip_code
        };
      } catch (error) {
        toast.error(error?.response?.data.message ?? 'Something went wrong!');
      }
    }
    if (differ.delivery_zip_code) {
      try {
        const deliveryResponse = await getCityStateNames(
          differ.delivery_zip_code
        );
        differ.delivery_address = {
          ...data.delivery_address,
          city: deliveryResponse.cityName,
          state: deliveryResponse.stateName,
          zip: differ.delivery_zip_code
        };
      } catch (error) {
        toast.error(error?.response?.data.message ?? 'Something went wrong!');
      }
    }
    if (!differ.tracking_details && data.service_type === BLANKET_WRAP) {
      differ.tracking_details = {
        trackingNumber:
          form?.trackingNumber ?? form?.tracking_details?.trackingNumber
      };
    }

    if (differ.tracking_details && data.service_type === BLANKET_WRAP) {
      if (!differ?.tracking_details?.trackingNumber)
        return toast.error('Tracking Number required');
      if (quote.bidStatus === SALES_REP_PENDING_QUOTE) {
        differ.bidStatus = ENROUTE_QUOTE;
        differ.assignDateTime = moment().format('MM/DD/YYYY HH:mm');
      }
      differ.tracking_details.trackingNumberHistory = [
        ...(data?.tracking_details?.trackingNumberHistory || []),
        quote?.tracking_details?.trackingNumber
      ];
    }
    if (differ[data.carrier_type] && data.service_type === LTL_FREIGHT) {
      if (!differ[data.carrier_type]?.pickupNumber)
        return toast.error('Tracking Number required');
      const defaulTracking = {
        trackingNumber: quote[data.carrier_type]?.pickupNumber,
        shipmentStatus: {
          statusCd: 1
        },
        estimatedDelivery: quote[data.carrier_type]?.transitDate
      };
      differ.tracking_details = data?.tracking_details || defaulTracking;
      differ.tracking_details.trackingNumber =
        differ[data.carrier_type]?.pickupNumber;
      differ.tracking_details.trackingNumberHistory = [
        ...(data?.tracking_details?.trackingNumberHistory || []),
        quote[data.carrier_type]?.pickupNumber
      ];
    } else {
      differ = _.omit(
        differ,
        VENDORS.map((vendor) => vendor.name)
      );
    }

    if (
      (quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID) &&
      (differ?.pickup_address || differ?.delivery_address)
    ) {
      if (!differ?.pickup_address?.address1) {
        delete differ.pickup_address;
      }
      if (!differ?.delivery_address?.address1) {
        delete differ.delivery_address;
      }
    }
    if (quote.bidStatus === PENDING_QUOTE && quote.bidType === FIXED_AMOUNT) {
      if (differ.pickup_close_time === '') delete differ.pickup_close_time;
      if (differ.delivery_close_time === '') delete differ.delivery_close_time;
    }

    try {
      const response = await updateShipmentService(differ);
      if (response?.data?.flag) {
        window.location.reload();
        return toast.success(response.data.message);
      }
    } catch (error) {
      return toast.error(error.message);
    }
  };

  useEffect(() => {
    if (quote) {
      const updatedCargo = quote.cargos.map((cargo) => {
        return {
          ...cargo,
          package_type: cargo?.package_type?.[0]?.id,
          goods_type: cargo?.goods_type?.map((type) => type.id),
          goods_condition: cargo?.goods_condition?.id
        };
      });

      const quoteData = {
        service_type: quote.service_type,
        pickup_zip_code: quote.pickup_zip_code,
        pickup_date:
          quote.pickup_date !== 'flexible date'
            ? new Date(quote.pickup_date)
            : '',
        pickup_time:
          quote.pickup_time !== 'flexible time'
            ? new Date(moment(quote.pickup_time, 'h:mm a').format())
            : '',
        pickup_close_time: quote.pickup_close_time
          ? new Date(moment(quote.pickup_close_time, 'h:mm a').format())
          : '',
        pickup_special_instructions: quote.pickup_special_instructions,
        pickup_service_options: quote.pickup_service_options.map(
          (service) => service.id
        ),
        delivery_date:
          quote.delivery_date !== 'flexible date'
            ? new Date(quote.delivery_date)
            : '',
        delivery_time:
          quote.delivery_time !== 'flexible time'
            ? new Date(moment(quote.delivery_time, 'h:mm a').format())
            : '',
        delivery_close_time: quote.delivery_close_time
          ? new Date(moment(quote.delivery_close_time, 'h:mm a').format())
          : '',
        delivery_zip_code: quote.delivery_zip_code,
        delivery_special_instructions: quote.delivery_special_instructions,
        delivery_service_options: quote.delivery_service_options.map(
          (service) => service.id
        ),
        special_instructions: quote.special_instructions,
        cargos: updatedCargo,
        estimate: quote.estimate,
        tarps_value: quote.tarps_value,
        amount: quote.amount,
        netCharge: quote.netCharge,
        bidType: quote.bidType,
        commission: quote.commission,
        carrier_type: quote.carrier_type,
        abf: quote.abf,
        xpo: quote.xpo,
        forwardair: quote.forwardair,
        fedex: quote.fedex,
        pickup_address: quote.pickup_address || { zip: quote.pickup_zip_code },
        delivery_address: quote.delivery_address || {
          zip: quote.delivery_zip_code
        },
        tracking_details: quote?.tracking_details,
        driverName: quote?.driverName,
        driverPhone: quote?.driverPhone,
        assignedCarrier: quote?.assignedCarrier,
        phoneNumber: quote?.carrierDetails?.phone,
        email: quote?.carrierDetails?.email,
        shipperName: quote?.company?.name,
        salesRepName: quote?.salesRepName,
        additional_charges: quote?.additional_charges || []
      };

      const { revenueCharge } = additionalCharges(quoteData);
      const { carrierCharge } = additionalCharges(quoteData);

      if (quote.service_type !== LTL_FREIGHT) {
        if (quote.bidType === QUOTE_FROM_NET) {
          quoteData.carrierPrice = (
            Number(quote.estimate) -
            Number(quote.commission) +
            Number(quote.tarps_value_carrier) -
            insuranceMarkup +
            Number(carrierCharge)
          ).toFixed(2);

          quoteData.estimate =
            Number(quote.estimate) +
            Number(quote.tarps_value) +
            Number(revenueCharge);
        }

        if (quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID) {
          quoteData.carrierPrice = (
            Number(quote.amount) -
            Number(quote.netCharge) +
            Number(quote.tarps_value_carrier) -
            insuranceMarkup +
            Number(carrierCharge)
          ).toFixed(2);
          quoteData.amount =
            Number(quote.amount) +
            Number(quote.tarps_value) +
            Number(revenueCharge);
        }
      } else {
        quoteData[quoteData.carrier_type] = {
          ...quoteData[quoteData.carrier_type],
          estimate:
            Number(quoteData[quoteData.carrier_type]?.estimate) +
            Number(revenueCharge),
          [`${quoteData.carrier_type}_estimate`]:
            Number(
              quoteData?.[quoteData.carrier_type]?.[
                `${quoteData.carrier_type}_estimate`
              ]
            ) + carrierCharge,
          commission:
            Number(quoteData[quoteData.carrier_type]?.commission) +
            (revenueCharge - carrierCharge)
        };
      }
      if (quote.bidType === FIXED_AMOUNT && quote.bidStatus === PENDING_QUOTE) {
        setValidation(opt2);
      } else {
        setValidation(validationSchema);
      }
      reset(quoteData);
    }
  }, [quote]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const ltlCarriers = ['abf', 'xpo', 'forwardair', 'fedex'];

      if (
        quote?.service_type === LTL_FREIGHT &&
        ltlCarriers.some((carrier) => Object.keys(errors).includes(carrier))
      ) {
        ltlCarriers.forEach((carrier) => {
          if (errors[carrier]) {
            Object.keys(errors[carrier]).forEach((err) => {
              toast.error(errors[carrier][err].message);
            });
          }
        });
      } else {
        Object.keys(errors).forEach((item) => {
          if (errors[item].message) {
            toast.error(errors[item].message);
          } else {
            toast.error('Validation Error!');
          }
        });
      }
    }
  }, [errors]);

  function toggleAccordion(value) {
    setOpen(open === value ? 0 : value);
  }

  const removeCharge = (idx) => {
    form.additional_charges?.splice(idx, 1);
    setValue('additional_charges', form.additional_charges);
  };

  const trackingNumber = () => {
    if (form?.tracking_details?.trackingNumber) {
      return form?.tracking_details?.trackingNumber;
    }
    if (quote?.trackingNumber) {
      return quote?.trackingNumber;
    }
    return 'Not Assigned';
  };

  return (
    <section className="pt-10">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <div className="flex justify-between">
            <h4 className="font-bold text-xl">Service Type</h4>
            <button
              type="button"
              className={`${
                quote.bidStatus === DELIVERED_QUOTE
                  ? 'hidden'
                  : 'inline-block text-navy-500 clear-both'
              }`}
              onClick={() => toggleAccordion(1)}
            >
              <u>Edit</u>
            </button>
          </div>
          <div
            className={
              open === 1
                ? `hidden`
                : `flex justify-start mt-3 overflow-auto transition-max-height duration-10 ease-in-out ml-1`
            }
          >
            {
              SERVICE_TYPES.find(
                (service) => service.value === form.service_type
              )?.label
            }
          </div>

          <div
            className={
              open === 1
                ? `overflow-auto transition-max-height duration-700 ease-in-out`
                : 'hidden'
            }
            style={{ maxHeight: open === 1 ? '50px' : '0px' }}
          >
            <select
              className="flex justify-start mt-3"
              name="service_type"
              {...register('service_type')}
              disabled={[ENROUTE_QUOTE, DELIVERED_QUOTE].includes(
                quote.bidStatus
              )}
            >
              {SERVICE_TYPES.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <hr />
        <div className="flex flex-col mt-5 mb-5">
          <div className="flex justify-between">
            <h4 className="font-bold text-xl">Pickup information</h4>
            {!(quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser) && (
              <button
                type="button"
                className="inline-block text-navy-500 clear-both"
                onClick={() => toggleAccordion(2)}
              >
                <u>Edit</u>
              </button>
            )}
          </div>
          <div
            className={
              open === 2 ||
              errors.pickup_address ||
              errors.pickupDate ||
              errors.pickup_close_time
                ? `hidden`
                : `flex flex-col justify-start mt-3 overflow-auto transition-max-height duration-700 ease-in-out ml-1 space-y-3`
            }
          >
            <div className="flex justify-start flex-col space-y-1">
              <h2 className="flex font-semibold">Ship from</h2>
              {quote.pickup_address ? (
                <>
                  <p className="flex">{`${form?.pickup_address?.company}`}</p>
                  <p className="flex">{`${form?.pickup_address?.address1}, ${
                    form?.pickup_address?.address2
                      ? `${form?.pickup_address?.address2},`
                      : ''
                  } ${form?.pickup_address?.city}, ${
                    form?.pickup_address?.state
                  }, ${form?.pickup_address?.country}, ${
                    form?.pickup_zip_code
                  }`}</p>
                  <p className="flex">{`${form?.pickup_address?.contactperson}, ${form?.pickup_address?.contactnumber}`}</p>
                </>
              ) : (
                <p className="flex">{quote.pickup_zip_code}</p>
              )}
            </div>

            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Pickup date</h2>
              <p className="flex ">
                {form.pickup_date
                  ? moment(form.pickup_date).format('MM/DD/YYYY')
                  : 'flexible date'}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Pickup time</h2>
              <p className="flex ">
                {form.pickup_time
                  ? moment(form.pickup_time).format('h:mm A')
                  : 'flexible time'}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Pickup close time</h2>
              <p className="flex ">
                {form.pickup_close_time
                  ? moment(form.pickup_close_time).format('h:mm A')
                  : 'N/A'}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Pickup service</h2>
              <p className="flex ">
                {form?.pickup_service_options
                  ?.map(
                    (service) =>
                      PICKUP_SERVICE_OPTION.find((ser) => ser.value === service)
                        ?.label
                  )
                  .join(', ')}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Special pickup instruction</h2>
              <p className="flex">{form.pickup_special_instructions}</p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Shipper Ref#</h2>
              <p className="flex">{form?.pickup_address?.reference}</p>
            </div>
          </div>
          <div
            className="overflow-auto transition-max-height duration-700 ease-in-out"
            style={{
              maxHeight:
                open === 2 ||
                errors.pickup_address ||
                errors.pickupDate ||
                errors.pickup_close_time
                  ? `900px`
                  : '0px'
            }}
          >
            <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Ship from
              </h3>
              <div className="clear-both pt-4">
                <div className="mb-4 w-1/2 pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Company Name
                  </label>
                  <input
                    {...register(`pickup_address.company`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.company && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address?.company?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4  w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Address1
                  </label>
                  <input
                    {...register(`pickup_address.address1`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.address1 && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address.address1?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Address2
                  </label>
                  <input
                    {...register(`pickup_address.address2`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.address2 && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address.address2?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4  w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    City
                  </label>
                  <input
                    {...register(`pickup_address.city`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.city && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address?.city?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4  w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    State
                  </label>
                  <input
                    {...register(`pickup_address.state`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.state && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address?.state?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4  w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Pickup Zip
                  </label>
                  <input
                    {...register(`pickup_zip_code`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors.pickup_zip_code && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_zip_code?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4  w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Country
                  </label>
                  <input
                    {...register(`pickup_address.country`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.country && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address?.country?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4  w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Contact Name
                  </label>
                  <input
                    {...register(`pickup_address.contactperson`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.contactperson && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address?.contactperson?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Contact Number
                  </label>
                  <input
                    {...register(`pickup_address.contactnumber`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.pickup_address?.contactnumber && (
                    <span className="text-xs text-red-700">
                      {errors?.pickup_address?.contactnumber?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Pickup date
              </h3>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <DatePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value}
                  />
                )}
                name="pickup_date"
                control={control}
              />
              {errors.pickupDate?.type === 'required' && (
                <p className="flex justify-start text-red-700">
                  pickup date is required
                </p>
              )}
            </div>

            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Pickup time
              </h3>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value}
                  />
                )}
                name="pickup_time"
                control={control}
              />
              {errors.pickupDate?.type === 'required' && (
                <p className="flex justify-start text-red-700">
                  pickup time is required
                </p>
              )}
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Pickup close time
              </h3>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value}
                  />
                )}
                name="pickup_close_time"
                control={control}
              />
              {errors?.pickup_close_time && (
                <p className="flex justify-start text-red-700">
                  {errors?.pickup_close_time?.message}
                </p>
              )}
            </div>
            <div className="flex flex-col my-3">
              <h3 className="flex justify-start font-bold text-xl">
                Pickup service
              </h3>
              {PICKUP_SERVICE_OPTION.map((pickupOption) => {
                return (
                  <div key={pickupOption.value} className="flex justify-start">
                    <label>
                      <input
                        {...register('pickup_service_options')}
                        type="checkbox"
                        value={pickupOption.value}
                      />
                      <span>{pickupOption.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Special pickup instruction
              </h3>
              <input
                {...register('pickup_special_instructions')}
                name="pickup_special_instructions"
                type="text"
                className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2 mt-2"
              />
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Shipper Ref#
              </h3>
              <input
                {...register('pickup_address.reference')}
                type="text"
                className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2 my-2"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="flex flex-col mt-5 mb-5">
          <div className="flex justify-between">
            <h4 className="font-bold text-xl">Delivery information</h4>
            {!(quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser) && (
              <button
                type="button"
                className="inline-block text-navy-500 clear-both"
                onClick={() => toggleAccordion(3)}
              >
                <u>Edit</u>
              </button>
            )}
          </div>
          <div
            className={
              open === 3 ||
              errors.delivery_address ||
              errors.deliveryDate ||
              errors.delivery_close_time
                ? `hidden`
                : `flex flex-col justify-start mt-3 overflow-auto transition-max-height duration-700 ease-in-out ml-1 space-y-3`
            }
          >
            <div className="flex justify-start flex-col space-y-1">
              <h2 className="flex font-semibold">Ship to</h2>
              {quote.delivery_address ? (
                <>
                  {' '}
                  <p className="flex">{`${form?.delivery_address?.company}`}</p>
                  <p className="flex">{`${form?.delivery_address?.address1}, ${
                    form?.delivery_address?.address2
                      ? `${form?.delivery_address?.address2},`
                      : ''
                  } ${form?.delivery_address?.city}, ${
                    form?.delivery_address?.state
                  }, ${form?.delivery_address?.country}, ${
                    form?.delivery_zip_code
                  }`}</p>
                  <p className="flex">{`${form?.delivery_address?.contactperson}, ${form?.delivery_address?.contactnumber}`}</p>
                </>
              ) : (
                <p className="flex">{quote.delivery_zip_code}</p>
              )}
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Delivery date</h2>
              <p className="flex ">
                {form?.delivery_date
                  ? moment(form.delivery_date).format('MM/DD/YYYY')
                  : 'flexible time'}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Delivery time</h2>
              <p className="flex ">
                {form?.delivery_time
                  ? moment(form.delivery_time).format('h:mm A')
                  : 'flexible time'}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Delivery close time</h2>
              <p className="flex ">
                {form.delivery_close_time
                  ? moment(form.delivery_close_time).format('h:mm A')
                  : 'N/A'}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Delivery service</h2>
              <p className="flex">
                {form?.delivery_service_options
                  ?.map(
                    (service) =>
                      DELIVERY_SERVICE_OPTION.find(
                        (ser) => ser.value === service
                      )?.label
                  )
                  .join(', ')}
              </p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">
                Special delivery instruction
              </h2>
              <p className="flex">{quote.delivery_special_instructions}</p>
            </div>
            <div className="flex justify-start flex-col">
              <h2 className="flex font-semibold">Consignee Ref#</h2>
              <p className="flex">{form?.delivery_address?.reference}</p>
            </div>
          </div>
          <div
            className="overflow-auto transition-max-height duration-700 ease-in-out"
            style={{
              maxHeight:
                open === 3 ||
                errors.delivery_address ||
                errors.deliveryDate ||
                errors.delivery_close_time
                  ? `900px`
                  : '0px'
            }}
          >
            <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">Ship to</h3>
              <div className="clear-both pt-4">
                <div className="mb-4 w-1/2 pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Company Name
                  </label>
                  <input
                    {...register(`delivery_address.company`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.company && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address?.company?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Address1
                  </label>
                  <input
                    {...register(`delivery_address.address1`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.address1 && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address.address1?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Address2
                  </label>
                  <input
                    {...register(`delivery_address.address2`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.address2 && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address.address2?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    City
                  </label>
                  <input
                    {...register(`delivery_address.city`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.city && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address?.city?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    State
                  </label>
                  <input
                    {...register(`delivery_address.state`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.state && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address?.state?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4 w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Delivery Zip
                  </label>
                  <input
                    {...register(`delivery_zip_code`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors.delivery_zip_code && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_zip_code?.message}
                    </span>
                  )}
                </div>
                <div className="mb-4 w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Country
                  </label>
                  <input
                    {...register(`delivery_address.country`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.country && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address?.country?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Contact Name
                  </label>
                  <input
                    {...register(`delivery_address.contactperson`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.contactperson && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address?.contactperson?.message}
                    </span>
                  )}
                </div>

                <div className="mb-4 w-1/2 float-left pr-1">
                  <label
                    className="w-full block text-black font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Contact Number
                  </label>
                  <input
                    {...register(`delivery_address.contactnumber`)}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                  />
                  {errors?.delivery_address?.contactnumber && (
                    <span className="text-xs text-red-700">
                      {errors?.delivery_address?.contactnumber?.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Delivery date
              </h3>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <DatePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value}
                  />
                )}
                name="delivery_date"
                control={control}
              />
              {errors.deliveryDate?.type === 'required' && (
                <p className="flex justify-start text-red-700">
                  Delivery date is required
                </p>
              )}
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Delivery time
              </h3>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value}
                  />
                )}
                name="delivery_time"
                control={control}
              />
              {errors.deliveryTime?.type === 'required' && (
                <p className="flex justify-start text-red-700">
                  Delivery Time is required
                </p>
              )}
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Delivery close time
              </h3>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value}
                  />
                )}
                name="delivery_close_time"
                control={control}
              />
              {errors?.delivery_close_time && (
                <p className="flex justify-start text-red-700">
                  {errors?.delivery_close_time?.message}
                </p>
              )}
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Delivery service
              </h3>
              {DELIVERY_SERVICE_OPTION.map((deliveryOption) => {
                return (
                  <div
                    key={deliveryOption.value}
                    className="flex justify-start"
                  >
                    <label>
                      <input
                        {...register('delivery_service_options')}
                        type="checkbox"
                        value={deliveryOption.value}
                      />
                      <span>{deliveryOption.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Special Delivery instruction
              </h3>
              <input
                {...register('delivery_special_instructions')}
                name="delivery_special_instructions"
                type="text"
                className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2 mt-2"
              />
            </div>
            <div className="flex flex-col  mt-3">
              <h3 className="flex justify-start font-bold text-xl">
                Consignee Ref#
              </h3>
              <input
                {...register('delivery_address.reference')}
                type="text"
                className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2 my-2"
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="flex flex-col mt-5 mb-5">
          <div className="flex justify-between">
            <h4 className="font-bold text-xl">Cargo details</h4>
            {!quote?.isInvoiceGenerated && (
              <button
                type="button"
                className="inline-block text-navy-500 clear-both"
                onClick={() => toggleAccordion(4)}
              >
                <u>Edit</u>
              </button>
            )}
          </div>
          <div
            className={
              open === 4 || errors.cargos
                ? `hidden`
                : `flex flex-col justify-start mt-3 overflow-auto transition-max-height duration-700 ease-in-out ml-1 space-y-3`
            }
          >
            {quote.cargos.map((cargo) => {
              return (
                <div className="mt-1" key={cargo?.id}>
                  <CargoDetailsCard cargo={cargo} />
                </div>
              );
            })}
            <div className="border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5 mt-6">
              <button
                type="button"
                className={`float-left inline-block w-1/3 py-2 text-sm font-bold text-center text-white ${quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser ? 'bg-gray-400 cursor-not-allowed' : 'transition duration-200 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700'}`}
                onClick={() => setIsPopup(true)}
                disabled={quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser}
              >
                Add New Cargo
              </button>
            </div>
          </div>
          {isPopup && (
            <div
              className="fixed inset-0 z-10 overflow-y-auto backdrop-filter 
                backdrop-brightness-50"
            >
              <div className="flex items-center px-4 py-8">
                <div className="relative w-full py-8 bg-white rounded-md shadow-lg">
                  <AddNewCargo
                    quote={quote}
                    isPopup={isPopup}
                    setIsPopup={(data) => setIsPopup(data)}
                  />
                </div>
              </div>
            </div>
          )}
          <div
            className="overflow-auto transition-max-height duration-700 ease-in-out"
            style={{
              maxHeight: open === 4 || errors.cargos ? `900px` : '0px'
            }}
          >
            {quote.cargos.map((cargo, index) => {
              return (
                <div
                  className="focus-within:border-gray-200 pt-5 clear-both p-4"
                  key={cargo?.id}
                >
                  <h3 className="text-left text-xl block mb-6">{`${cargo.quantity} - ${cargo.desc}`}</h3>
                  <div className="grid grid-cols-4 float-left mb-4">
                    {PACKAGE_TYPE.map((packageType) => {
                      return (
                        <div className="w-1/4" key={packageType?.value}>
                          <label>
                            <input
                              {...register(`cargos[${index}].package_type`)}
                              type="radio"
                              value={packageType.value}
                            />
                            <span className="ml-1">{packageType.label}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mb-4  w-1/4 float-left pr-1">
                    <label
                      className="w-full block text-black text-sm font-bold mb-2"
                      htmlFor="ship-from"
                    >
                      Length
                    </label>
                    <input
                      {...register(`cargos[${index}].length`)}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      // min="0"
                    />
                    {errors.cargos?.[index]?.length && (
                      <span className="text-xs text-red-700">
                        {errors.cargos?.[index]?.length?.message}
                      </span>
                    )}
                  </div>

                  <div className="mb-4  w-1/4 float-left pr-1">
                    <label
                      className="w-full block text-black text-sm font-bold mb-2"
                      htmlFor="ship-from"
                    >
                      Width
                    </label>
                    <input
                      {...register(`cargos[${index}].width`)}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      // min="0"
                    />
                    {errors.cargos?.[index]?.width && (
                      <span className="text-xs text-red-700">
                        {errors.cargos?.[index]?.width?.message}
                      </span>
                    )}
                  </div>

                  <div className="mb-4 w-1/4 float-left pr-1">
                    <label
                      className="w-full block text-black text-sm font-bold mb-2"
                      htmlFor="ship-from"
                    >
                      Height
                    </label>
                    <input
                      {...register(`cargos[${index}].height`)}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      // min="0"
                    />
                    {errors.cargos?.[index]?.height && (
                      <span className="text-xs text-red-700">
                        {errors.cargos?.[index]?.height?.message}
                      </span>
                    )}
                  </div>

                  <div className="mb-4 w-1/4 float-left pr-1">
                    <label
                      className="w-full block text-black text-sm font-bold mb-2"
                      htmlFor="ship-from"
                    >
                      Weight
                    </label>
                    <input
                      {...register(`cargos[${index}].weight`)}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.cargos?.[index]?.weight && (
                      <span className="text-xs text-red-700">
                        {errors.cargos?.[index]?.weight?.message}
                      </span>
                    )}
                  </div>

                  {quote?.service_type === LTL_FREIGHT ? (
                    <div className="text-sm font-bold clear-both">
                      <div className="w-1/2 float-left flex-1">
                        <p className="text-left w-full text-black mb-3">
                          Freight Class
                        </p>
                        <div className="flex mb-3 w-1/2">
                          <select
                            {...register(`cargos[${index}].freight_class`)}
                            className="flex leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2"
                          >
                            {FREIGHT_CLASS.map((option) => (
                              <option
                                value={option.value}
                                className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.cargos?.[index]?.freight_class && (
                            <span className="text-xs text-red-700">
                              {errors.cargos?.[index]?.freight_class?.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="w-1/2 float-right flex-1">
                        <p className="text-left w-full text-black mb-3">
                          NMFC Code
                        </p>
                        <input
                          {...register(`cargos[${index}].nmfc_code`)}
                          className="flex leading-tight shadow appearance-none w-1/2 text-xs border-gray-200 border-solid border bg-white py-2 px-2 mb-3"
                          type="string"
                        />
                        {errors.cargos?.[index]?.nmfc_code && (
                          <span className="text-xs text-red-700">
                            {errors.cargos?.[index]?.nmfc_code?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div className="text-sm font-bold clear-both">
                    <div className="w-1/2 float-left">
                      <p className="text-left w-full text-black mb-3">
                        Goods Condition
                      </p>
                      <div className="flex mb-3 w-1/2">
                        <select
                          {...register(`cargos[${index}].goods_condition`)}
                          className="flex leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2"
                        >
                          {GOODS_CONDITION.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}
                              className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.cargos?.[index]?.goods_condition && (
                          <span className="text-xs text-red-700">
                            {errors.cargos?.[index]?.goods_condition?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-1/2 float-left">
                      <p className="text-left w-full text-black mb-3">
                        Quantity
                      </p>
                      <div className="flex mb-3 w-1/2">
                        <input
                          {...register(`cargos[${index}].quantity`)}
                          className="flex leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-2 px-2"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                        />
                        {errors.cargos?.[index]?.quantity && (
                          <span className="text-xs text-red-700">
                            {errors.cargos?.[index]?.quantity?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm clear-both pt-6 space-x-4">
                    {GOODS_TYPE.map((goodsType) => {
                      return (
                        <div className="float-left" key={goodsType?.value}>
                          <label>
                            <input
                              {...register(`cargos[${index}].goods_type`)}
                              type="checkbox"
                              value={goodsType.value}
                            />
                            <span className="ml-1">{goodsType.label}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="pt-5 clear-both">
                    <textarea
                      {...register(`cargos[${index}].desc`)}
                      className="resize border w-full"
                      placeholder="make, model, serial number"
                    />
                    {errors.cargos?.[index]?.desc && (
                      <span className="text-xs text-red-700">
                        {errors.cargos?.[index]?.desc?.message}
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-5">
          <div className="flex justify-between">
            <h4 className="font-bold text-xl">Billing Details</h4>
            {!(quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser) && (
              <button
                type="button"
                className="inline-block text-navy-500 clear-both"
                onClick={() => toggleAccordion(5)}
              >
                <u>Edit</u>
              </button>
            )}
          </div>

          <div
            className={
              open === 5
                ? `hidden`
                : `flex justify-start mt-3 overflow-auto transition-max-height duration-10 ease-in-out ml-1 flex-col space-y-3`
            }
          >
            {quote.service_type === LTL_FREIGHT ? (
              <>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Carrier</h2>
                  <p className="flex">
                    {`${
                      VENDORS.find((e) => e.name === form.carrier_type)
                        ?.display_name
                    } (${
                      VENDORS.find((e) => e.name === form.carrier_type)?.service
                    })`}
                  </p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Shipment Revenue</h2>
                  <p className="flex">$ {getQuotePrice(quote)}</p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Carrier Price</h2>
                  <p className="flex">
                    ${' '}
                    {Number(
                      `${
                        form?.[form.carrier_type]?.[
                          `${form.carrier_type}_estimate`
                        ]
                      }`
                    )?.toFixed(2)}
                  </p>
                </div>
                {quote?.is_insurance_required && (
                  <div className="flex justify-start flex-col">
                    <h2 className="flex font-semibold">Insurance Price</h2>
                    <p className="flex">$ {insuranceMarkup}</p>
                  </div>
                )}
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Shipment Profit</h2>
                  <p className="flex">
                    ${' '}
                    {Number(
                      `${form?.[form.carrier_type]?.commission}`
                    )?.toFixed(2)}
                  </p>
                </div>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">Shipper Name</h2>
                  <p className="flex">{form.shipperName}</p>
                </div>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">SalesRep Name</h2>
                  <p className="flex">{form.salesRepName}</p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Additional Charges</h2>
                  <p className="flex flex-col justify-start items-start">
                    {quote.additional_charges?.map((charge) => {
                      return (
                        <p className="capitalize">
                          {charge.feesType} -{' '}
                          {
                            ADDITIONAL_FEES?.find(
                              (fee) => fee.value === charge.name
                            )?.label
                          }{' '}
                          ($ {charge.fees})
                        </p>
                      );
                    })}
                  </p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Tracking Number</h2>
                  <p className="flex">
                    {`${
                      form?.[form.carrier_type]?.pickupNumber
                        ? form?.[form.carrier_type]?.pickupNumber
                        : 'Not assigned'
                    }`}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Shipment Revenue</h2>
                  <p className="flex">$ {getQuotePrice(quote)}</p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Carrier Price</h2>
                  <p className="flex">
                    {' '}
                    {form?.carrierPrice && form?.carrierPrice !== 'NaN'
                      ? `$ ${form.carrierPrice}`
                      : ''}
                  </p>
                </div>
                {quote?.is_insurance_required && (
                  <div className="flex justify-start flex-col">
                    <h2 className="flex font-semibold">Insurance Price</h2>
                    <p className="flex">$ {insuranceMarkup}</p>
                  </div>
                )}
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Shipment Profit</h2>
                  <p className="flex">
                    {(quote.bidType === FIXED_AMOUNT ||
                      quote.bidType === OPEN_BID) &&
                      !Number.isNaN(form.amount) &&
                      form.amount && (
                        <div>
                          ${' '}
                          {(
                            Number(form.amount) -
                            Number(form.carrierPrice) -
                            insuranceMarkup
                          )?.toFixed(2)}
                        </div>
                      )}
                    {quote.bidType !== FIXED_AMOUNT &&
                      quote.bidType !== OPEN_BID && (
                        <>
                          ${' '}
                          {(
                            Number(form.estimate) -
                            Number(form.carrierPrice) -
                            (quote?.service_type === BLANKET_WRAP
                              ? 0
                              : insuranceMarkup)
                          )?.toFixed(2)}
                        </>
                      )}
                  </p>
                </div>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">Shipper Name</h2>
                  <p className="flex">{form.shipperName}</p>
                </div>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">SalesRep Name</h2>
                  <p className="flex">{form.salesRepName}</p>
                </div>
                {quote?.additional_charges?.length ? (
                  <div className="flex justify-start flex-col">
                    <h2 className="flex font-semibold">Additional Charges</h2>
                    <p className="flex flex-col justify-start items-start">
                      {quote.additional_charges?.map((charge) => {
                        return (
                          <p className="capitalize">
                            {charge.feesType} -{' '}
                            {
                              ADDITIONAL_FEES?.find(
                                (fee) => fee.value === charge.name
                              )?.label
                            }{' '}
                            ($ {charge.fees})
                          </p>
                        );
                      })}
                    </p>
                  </div>
                ) : null}
                {(quote.service_type === BLANKET_WRAP ||
                  currentServiceType === BLANKET_WRAP) && (
                  <div className="flex justify-start flex-col">
                    <h2 className="flex font-semibold">Tracking Number</h2>
                    <p className="flex">{trackingNumber()}</p>
                  </div>
                )}
                {(quote.service_type === LTL_FREIGHT ||
                  currentServiceType === LTL_FREIGHT) && (
                  <div className="flex justify-start flex-col">
                    <h2 className="flex font-semibold">Tracking Number1</h2>
                    <p className="flex">
                      {`${
                        form?.[form.carrier_type]?.pickupNumber
                          ? form?.[form.carrier_type]?.pickupNumber
                          : 'Not assigned'
                      }`}
                    </p>
                  </div>
                )}
                {![LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
                [ENROUTE_QUOTE, DELIVERED_QUOTE].includes(quote.bidStatus) ? (
                  <>
                    <div className="flex justify-start flex-col">
                      <h2 className="flex font-semibold">Assigned To</h2>
                      <p className="flex">{form?.assignedCarrier}</p>
                    </div>
                    <div className="flex justify-start flex-col">
                      <h2 className="flex font-semibold">
                        Carrier Phone Number
                      </h2>
                      <p className="flex">
                        <a href={`tel:${form?.phoneNumber}`}>
                          {form?.phoneNumber}
                        </a>
                      </p>
                    </div>
                    <div className="flex justify-start flex-col">
                      <h2 className="flex font-semibold">Carrier Email</h2>
                      <p className="flex">
                        <a href={`mailto:${form?.email}`}>{form?.email}</a>
                      </p>
                    </div>
                    <div className="flex justify-start flex-col">
                      <h2 className="flex font-semibold">Driver Name</h2>
                      <p className="flex">{form?.driverName}</p>
                    </div>
                    <div className="flex justify-start flex-col">
                      <h2 className="flex font-semibold">Driver Phone</h2>
                      <p className="flex">
                        <a href={`tel:${form?.driverPhone}`}>
                          {form?.driverPhone}
                        </a>
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>

          <div
            className={
              open === 5
                ? `overflow-auto transition-max-height duration-700 ease-in-out`
                : 'hidden'
            }
            style={{ maxHeight: open === 5 ? '700px' : '0px' }}
          >
            {quote.service_type === LTL_FREIGHT ? (
              <>
                {' '}
                <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                  <h3 className="flex justify-start font-bold text-xl">
                    Carrier
                  </h3>
                  <select
                    className="flex justify-start mt-3"
                    name="service_type"
                    {...register('carrier_type')}
                  >
                    {VENDORS.map((option) => (
                      <option
                        value={option.name}
                      >{`${option.display_name} (${option.service})`}</option>
                    ))}
                  </select>
                </div>
                <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                  <h3 className="flex justify-start font-bold text-xl">
                    Shipment Revenue
                  </h3>
                  <span className="flex justify-start">
                    <span className="py-2"> $ </span>
                    <input
                      {...register(`${form.carrier_type}.estimate`)}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      onChange={(e) => {
                        const commission =
                          Number(e.target.value) -
                          Number(
                            form?.[form.carrier_type]?.[
                              `${form.carrier_type}_estimate`
                            ]
                          ) -
                          insuranceMarkup;
                        setValue(`${form.carrier_type}.commission`, commission);
                        setValue('commission', commission);
                      }}
                    />
                    <span className="w-1/2 bg-navy-500 ml-4 text-white ">
                      {' '}
                      <button
                        type="button"
                        className="px-2 text-xs flex justify-center gap-2 items-center"
                        onClick={() => setAddFees('revenue')}
                      >
                        <span className="text-2xl  mb-1">+</span> Add New
                        Fees/Charges
                      </button>
                    </span>
                  </span>
                </div>
                <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                  <h3 className="flex justify-start font-bold text-xl">
                    Carrier Price
                  </h3>
                  <span className="flex justify-start">
                    <span className="py-2"> $ </span>
                    <input
                      {...register(
                        `${form.carrier_type}.${form.carrier_type}_estimate`
                      )}
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      onChange={(e) => {
                        const commission =
                          Number(form?.[form.carrier_type]?.estimate) -
                          Number(e.target.value) -
                          insuranceMarkup;
                        setValue(`${form.carrier_type}.commission`, commission);
                        setValue('commission', commission);
                      }}
                    />
                    <span className="w-1/2 bg-navy-500 ml-4 text-white ">
                      {' '}
                      <button
                        type="button"
                        className="px-2 text-xs flex justify-center gap-2 items-center"
                        onClick={() => setAddFees('carrier')}
                      >
                        <span className="text-2xl  mb-1">+</span> Add New
                        Fees/Charges
                      </button>
                    </span>
                  </span>
                </div>
                {quote?.additional_charges?.length ? (
                  <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                    <h3 className="flex justify-start font-bold text-xl">
                      Additional Charges
                    </h3>

                    {form.additional_charges?.map((charge, index) => {
                      return (
                        <span className="flex justify-start flex-col mt-2">
                          <div className="flex items-center">
                            <span className="py-2"> $ </span>
                            <input
                              {...register(`additional_charges[${index}].fees`)}
                              className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                            />
                            <span className="w-2/3 text-xs text-left ml-2 capitalize">
                              {
                                ADDITIONAL_FEES?.find(
                                  (e) => e.value === charge.name
                                )?.label
                              }{' '}
                              - <b>{charge.feesType}</b>
                            </span>
                            <button
                              type="button"
                              onClick={() => removeCharge(index)}
                            >
                              <MdDelete
                                color="#d22027"
                                className=" bg-red-200 p-1 h-5 w-5 shadow-md rounded-sm "
                              />
                            </button>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                ) : null}
                <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                  <h3 className="flex justify-start font-bold text-xl">
                    Tracking Number
                  </h3>
                  <span className="flex justify-start">
                    <input
                      {...register(`${form.carrier_type}.pickupNumber`)}
                      type="text"
                      className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                    />
                  </span>
                </div>
              </>
            ) : (
              <>
                {quote.bidType !== OPEN_BID ||
                quote.bidStatus === ENROUTE_QUOTE ? (
                  <>
                    <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                      <h3 className="flex justify-start font-bold text-xl">
                        Shipment Revenue
                      </h3>
                      <span className="flex justify-start">
                        <span className="py-2"> $ </span>
                        <input
                          {...register(
                            (quote.bidType === FIXED_AMOUNT ||
                              quote.bidType === OPEN_BID) &&
                              quote.amount
                              ? `amount`
                              : 'estimate'
                          )}
                          className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                          onChange={(e) =>
                            setValue(
                              quote.bidType === FIXED_AMOUNT ||
                                quote.bidType === OPEN_BID
                                ? 'netCharge'
                                : 'commission',
                              Number(e.target.value) -
                                Number(form.carrierPrice) -
                                (Number(quote.tarps_value) -
                                  Number(quote.tarps_value_carrier)) -
                                insuranceMarkup
                            )
                          }
                        />
                        <span className="w-1/2 bg-navy-500 ml-4 text-white ">
                          {' '}
                          <button
                            type="button"
                            className="px-2 text-xs flex justify-center gap-2 items-center"
                            onClick={() => setAddFees('revenue')}
                          >
                            <span className="text-2xl  mb-1">+</span> Add New
                            Fees/Charges
                          </button>
                        </span>
                      </span>
                    </div>
                    <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                      <h3 className="flex justify-start font-bold text-xl">
                        Carrier Price
                      </h3>
                      <span className="flex justify-start">
                        <span className="py-2"> $ </span>
                        <input
                          {...register('carrierPrice', {
                            required: true
                          })}
                          className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                          onChange={(e) =>
                            form.bidType === FIXED_AMOUNT ||
                            form.bidType === OPEN_BID
                              ? setValue(
                                  'netCharge',
                                  Number(form.amount) -
                                    Number(e.target.value) -
                                    (Number(quote.tarps_value) -
                                      Number(quote.tarps_value_carrier)) -
                                    insuranceMarkup
                                )
                              : setValue(
                                  'commission',
                                  Number(form.estimate) -
                                    Number(e.target.value) -
                                    (Number(quote.tarps_value) -
                                      Number(quote.tarps_value_carrier)) -
                                    insuranceMarkup
                                )
                          }
                        />
                        <span className="w-1/2 bg-navy-500 ml-4 text-white ">
                          {' '}
                          <button
                            type="button"
                            className="px-2 text-xs flex justify-center gap-2 items-center"
                            onClick={() => setAddFees('carrier')}
                          >
                            <span className="text-2xl pb-1">+</span> Add New
                            Fees/Charges
                          </button>
                        </span>
                      </span>
                    </div>
                    {quote?.additional_charges?.length ? (
                      <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                        <h3 className="flex justify-start font-bold text-xl">
                          Additional Charges
                        </h3>

                        {form.additional_charges?.map((charge, index) => {
                          return (
                            <span className="flex justify-start flex-col mt-2">
                              <div className="flex items-center">
                                <span className="py-2"> $ </span>
                                <input
                                  {...register(
                                    `additional_charges[${index}].fees`
                                  )}
                                  className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                                />
                                <span className="w-2/3 text-xs text-left ml-2 capitalize">
                                  {
                                    ADDITIONAL_FEES?.find(
                                      (e) => e.value === charge.name
                                    )?.label
                                  }{' '}
                                  - <b>{charge.feesType}</b>
                                </span>
                                <button
                                  type="button"
                                  onClick={() => removeCharge(index)}
                                >
                                  <MdDelete
                                    color="#d22027"
                                    className=" bg-red-200 p-1 h-5 w-5 shadow-md rounded-sm "
                                  />
                                </button>
                              </div>
                            </span>
                          );
                        })}
                      </div>
                    ) : null}
                    {quote.service_type === BLANKET_WRAP ||
                    currentServiceType === BLANKET_WRAP ? (
                      <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                        <h3 className="flex justify-start font-bold text-xl">
                          Tracking Number
                        </h3>
                        <span className="flex justify-start">
                          <input
                            {...register('trackingNumber')}
                            type="text"
                            className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                            defaultValue={
                              form?.tracking_details?.trackingNumber ??
                              quote?.trackingNumber
                            }
                          />
                        </span>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {![LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
                quote.bidStatus === ENROUTE_QUOTE ? (
                  <>
                    <div className="overflow-auto transition-max-height duration-700 ease-in-out flex flex-col  mt-3">
                      <h3 className="flex justify-start font-bold text-xl">
                        Driver Name
                      </h3>
                      <span className="flex justify-start">
                        <input
                          {...register(`driverName`)}
                          type="text"
                          className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                        />
                      </span>
                      {errors.driverName && (
                        <p className="flex justify-start text-red-700">
                          {errors?.driverName?.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col  mt-3">
                      <h3 className="flex justify-start font-bold text-xl">
                        Driver Phone
                      </h3>
                      <input
                        {...register(`driverPhone`)}
                        type="text"
                        className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                      />
                      {errors.driverPhone && (
                        <p className="flex justify-start text-red-700">
                          {errors?.driverPhone?.message}
                        </p>
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
        <hr className="my-5" />
        {addFees ? (
          <AddFeesPopup
            quote={quote}
            feesType={addFees}
            setFeesType={setAddFees}
            fetchQuoteDetails={fetchQuoteDetails}
          />
        ) : null}
        <div className="flex flex-row gap-2">
          <button
            type="button"
            onClick={() => navigate(`/new-quote?quoteId=${quote.id}`)}
            className="flex flex-row justify-between items-center py-3 px-4 w-1/2
                            bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                            hover:border-navy-600 active:border-navy-700
                              text-sm font-bold transition duration-200 text-left"
          >
            Duplicate
            <RightArrow className="w-5 h-5 font-bold" />
          </button>
          <button
            type="submit"
            className={`flex flex-row justify-between items-center py-3 px-4 w-1/2 text-white text-sm font-bold
            ${quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser ? 'bg-gray-400 cursor-not-allowed' : 'bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700 transition duration-200'}`}
            disabled={quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser}
          >
            Update
            <RightArrow className="w-5 h-5 font-bold" />
          </button>
        </div>
      </form>
    </section>
  );
});

export default UpdateShipment;

UpdateShipment.propTypes = {
  quote: PropTypes.object.isRequired,
  fetchQuoteDetails: PropTypes.func.isRequired
};
