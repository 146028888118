import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoLocationSharp } from 'react-icons/io5';
import toast from 'react-hot-toast';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import Roles from '../../../constants/roles.constants';
import { getAddressFromLatLong } from '../../../services/geo.service';
import { DELIVERY_SERVICE_OPTION } from '../../../constants/quote.constants';
import { useAuthContext } from '../../../contexts/auth.context';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { loadAddressService } from '../../../services/quote.service';
import DeliveryServiceItem from '../DeliveryServiceItem';
import {
  DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT,
  DELIVERY_RESIDENTIAL,
  LTL_FREIGHT
} from '../../../constants/service_type.constants';
import { isDuplicate } from '../../../constants/generalConstants';

const DeliveryAddressCard = () => {
  const { authUser } = useAuthContext();
  const {
    currentCustomer,
    validate,
    setDeliveryServices,
    deliveryAddress,
    setDeliveryAddress,
    deliveryServices,
    serviceType,
    deliveryRef,
    lengthRef
  } = useContext(GlobalFormContext);

  const [address, setAddress] = useState('');
  const [IsChangeAdd, setIsChangeAdd] = useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [isAutofilled, setIsAutofilled] = useState(false);

  const handleSelect = async (value, placeId) => {
    try {
      let id = placeId;
      if (!id) {
        const result = await geocodeByAddress(value);
        id = result[0].place_id;
      }
      const [place] = await geocodeByPlaceId(id.trim());

      const { addr, postcode, city, state, country } =
        await getAddressFromLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          value,
          place.postcode_localities
        );

      setAddress(value);

      const deliveryLocationData = {
        delivery_to: `${addr} | ${postcode}, ${city}, ${state}, ${country}`,
        delivery_formatted_address: `${value} | ${placeId}`,
        delivery_zip_code: postcode,
        delivery_state: state,
        delivery_id: uuidv4(),
        delivery_date: 'flexible date',
        is_delivery_flexible: true,
        is_delivery_time_flexible: true,
        delivery_time: 'flexible time',
        delivery_close_time: '',
        delivery_special_instructions: '',
        deliveryLocation: null
      };

      setDeliveryAddress(deliveryLocationData);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
  };

  const handleKeyDown = (e, suggestions) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      e.preventDefault();

      if (suggestions.length) {
        const activeSuggestion = suggestions[0];
        handleSelect(activeSuggestion?.description, activeSuggestion?.placeId);
      }

      lengthRef.current.focus();
    }
  };

  async function loadAddresses() {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : currentCustomer?.id;
      const response = await loadAddressService(companyId, 'delivery');

      setUserAddress(response?.data?.data);
      const allDefaultAddress = response.data.data?.filter?.(
        (addr) => addr.isDefault
      );

      if (!allDefaultAddress.length && !isDuplicate()) {
        setAddress('');
        setDeliveryServices([DELIVERY_SERVICE_OPTION[0].value]);
      }

      if (allDefaultAddress.length && !isDuplicate()) {
        const defaultAddress = allDefaultAddress?.[0];
        setDeliveryServices(defaultAddress?.delivery_service_options);
        handleSelect(defaultAddress?.name, defaultAddress?.locationId);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const onAddressChange = (e) => {
    setIsChangeAdd(true);
    setAddress(e);
  };

  const handleAddSavedAddress = (addr) => {
    handleSelect(addr.name, addr.locationId);
    setDeliveryServices(addr.delivery_service_options);
  };

  useEffect(() => {
    if (Object.keys(currentCustomer).length) {
      loadAddresses();
    }
  }, [currentCustomer]);

  useEffect(() => {
    // Check if there's an address match in userAddress

    if (Object.keys(deliveryAddress).length) {
      const obj = { ...deliveryAddress };

      if (userAddress.length) {
        const addrData = userAddress?.find(
          (del) => del?.delivery_formatted_address?.trim() === address
        );
        let deliveryLocation = null;

        if (addrData) {
          deliveryLocation = addrData?.id;
        }
        obj.deliveryLocation = deliveryLocation;
      }

      obj.delivery_service_options = deliveryServices;

      setDeliveryAddress(obj);
    }
  }, [address, deliveryServices]);

  useEffect(() => {
    if (serviceType === LTL_FREIGHT) {
      if (
        deliveryServices.includes(DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        deliveryServices.includes(DELIVERY_RESIDENTIAL)
      ) {
        const updatedDeliveryServices = deliveryServices.filter(
          (item) =>
            item !== DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT &&
            item !== DELIVERY_RESIDENTIAL
        );
        setDeliveryServices(updatedDeliveryServices);

        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
    }
  }, [serviceType, deliveryServices]);

  useEffect(() => {
    if (!Object.keys(deliveryAddress).length) {
      setAddress('');
      setIsChangeAdd(false);
      setUserAddress([]);
    }
    if (
      !isAutofilled &&
      isDuplicate() &&
      !address &&
      Object.keys(deliveryAddress).length
    ) {
      const inputAdd = deliveryAddress?.delivery_formatted_address
        ?.split('|')[0]
        .trim();
      setAddress(inputAdd);
      setIsAutofilled(true);
    }
  }, [deliveryAddress]);

  return (
    <div className="flex flex-col gap-2 w-full items-start">
      <span className="font-medium ml-3">Ship To</span>
      <div className="flex flex-col bg-navy-20 p-3 w-full gap-4">
        <div className="flex flex-col items-start gap-2">
          <span className="text-gray-500">Delivery Address</span>
          <div className="flex flex-row justify-between items-center pr-2 w-full border gap-1 bg-white">
            <PlacesAutocomplete
              value={address ?? ''}
              onChange={(e) => onAddressChange(e)}
              onSelect={handleSelect}
              highlightFirstSuggestion
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div className="w-full">
                  <div className="flex gap-3 items-center">
                    <input
                      ref={deliveryRef}
                      className=" focus:outline-none w-full text-sm py-3 px-2"
                      {...getInputProps({
                        placeholder: 'Type address',
                        onKeyDown: (e) => handleKeyDown(e, suggestions)
                      })}
                    />
                  </div>

                  {IsChangeAdd && (
                    <div className="overflow-scroll max-h-48">
                      <div className="mt-2 space-y-3 px-2 py-2 border shadow-lg">
                        <div className="bg-gray-50 mt-3">
                          {suggestions.length > 0 && (
                            <>
                              <div className="flex flex-col gap-2 items-start">
                                <p className="text-gray-400">Other Address</p>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  const suggestionProps =
                                    getSuggestionItemProps(suggestion);
                                  const handleClick = (event) => {
                                    setDeliveryServices([
                                      DELIVERY_SERVICE_OPTION[0].value
                                    ]);
                                    suggestionProps.onClick(event); // Call the original onClick handler
                                  };
                                  return (
                                    <div
                                      key={suggestion.placeId}
                                      {...suggestionProps}
                                      onClick={handleClick}
                                      className={`flex justify-start gap-2 items-start cursor-pointer ${suggestion.active && 'bg-navy-50'}`}
                                    >
                                      <input
                                        className="quickQuote-radio"
                                        type="radio"
                                        value={suggestion.id}
                                      />
                                      <p className="quickQuote-description">
                                        {suggestion.description}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                              <hr className="mt-3" />
                            </>
                          )}
                        </div>
                        <p className="text-gray-400">Default Address</p>
                        <div className="flex flex-col mb-4 mt-4">
                          {userAddress
                            ?.filter((addr) => addr.isDefault)
                            ?.map((addr) => {
                              return (
                                <label
                                  key={addr?.id}
                                  className="flex flex-row flex-wrap gap-2 justify-start items-center cursor-pointer"
                                >
                                  <input
                                    type="radio"
                                    className="quickQuote-radio"
                                    value={addr.id}
                                    checked={addr.name === address}
                                    onChange={() =>
                                      handleSelect(addr.name, addr.locationId)
                                    }
                                  />
                                  <p>{addr.name}</p>
                                </label>
                              );
                            })}
                        </div>
                        <hr />
                        <div>
                          <p className="text-gray-400">Saved Address</p>
                          <div className="mb-4 mt-4 flex flex-col gap-2 ">
                            {userAddress
                              ?.filter((addr) => !addr.isDefault)
                              ?.map((addr) => {
                                return (
                                  <label
                                    key={addr.id}
                                    className="flex flex-row flex-wrap gap-2 justify-start items-center cursor-pointer"
                                  >
                                    <input
                                      type="radio"
                                      value={addr.id}
                                      className="quickQuote-radio"
                                      onChange={() =>
                                        handleAddSavedAddress(addr)
                                      }
                                      checked={addr.name === address}
                                    />
                                    <p>{addr.name}</p>
                                  </label>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
            {!IsChangeAdd && (
              <div className="">
                <button
                  type="button"
                  onClick={() => setIsChangeAdd(true)}
                  className="flex py-1 px-2 gap-1 items-center h-7 whitespace-normal max-w-max border rounded-3xl border-navy-500  text-navy-500 
     text-sm break-words"
                >
                  <IoLocationSharp size={20} />
                  Change
                </button>
              </div>
            )}
          </div>
          {validate && !address && (
            <span className="text-sm text-red-700">
              Please add a Delivery Location
            </span>
          )}
        </div>
        <hr />
        <div className="flex flex-col items-start gap-4">
          <span className="text-gray-500 font-medium">
            Select Delivery Service
          </span>
          {DELIVERY_SERVICE_OPTION.map((item) => (
            <DeliveryServiceItem key={item.value} deliveryServiceItem={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeliveryAddressCard;
