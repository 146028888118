import React, { useContext, useEffect, useState } from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import toast from 'react-hot-toast';
import Roles from '../../../constants/roles.constants';
import { getAddressFromLatLong } from '../../../services/geo.service';
import { PICKUP_SERVICE_OPTION } from '../../../constants/quote.constants';
import PickupServiceItem from '../PickupServiceItem';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { loadAddressService } from '../../../services/quote.service';
import { useAuthContext } from '../../../contexts/auth.context';
import {
  LTL_FREIGHT,
  PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT,
  PICKUP_RESIDENTIAL
} from '../../../constants/service_type.constants';
import { isDuplicate } from '../../../constants/generalConstants';

const PickupAddressCard = () => {
  const { authUser } = useAuthContext();
  const {
    currentCustomer,
    validate,
    setPickupServices,
    setPickupAddress,
    pickupServices,
    pickupAddress,
    serviceType,
    pickupRef,
    deliveryRef
  } = useContext(GlobalFormContext);

  const [address, setAddress] = useState('');
  const [IsChangeAdd, setIsChangeAdd] = useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [isAutofilled, setIsAutofilled] = useState(false);

  const handleSelect = async (value, placeId) => {
    try {
      let id = placeId;
      if (!id) {
        const result = await geocodeByAddress(value);
        id = result[0].place_id;
      }
      const [place] = await geocodeByPlaceId(id.trim());

      const { addr, postcode, city, state, country } =
        await getAddressFromLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          value,
          place.postcode_localities
        );

      setAddress(value);

      const pickupLocationData = {
        pickup_from: `${addr} | ${postcode}, ${city}, ${state}, ${country}`,
        pickup_formatted_address: `${value} | ${placeId}`,
        pickup_zip_code: postcode,
        pickup_state: state,
        pickup_id: uuidv4(),
        pickup_date: 'flexible date',
        is_pickup_flexible: true,
        is_pickup_time_flexible: true,
        pickup_time: 'flexible time',
        pickup_close_time: '',
        pickup_special_instructions: '',
        pickupLocation: null
      };

      setPickupAddress(pickupLocationData);
      deliveryRef.current.focus();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong !');
    }
  };

  const handleKeyDown = (e, suggestions) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      e.preventDefault();

      if (suggestions.length) {
        const activeSuggestion = suggestions[0];
        handleSelect(activeSuggestion?.description, activeSuggestion?.placeId);
      }

      deliveryRef.current.focus();
    }
  };

  const onAddressChange = (e) => {
    setIsChangeAdd(true);
    setAddress(e);
  };

  const loadAddresses = async () => {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : currentCustomer?.id;
      const response = await loadAddressService(companyId, 'pickup');
      setUserAddress(response?.data?.data);

      const allDefaultAddress = response.data.data?.filter?.(
        (addr) => addr.isDefault
      );

      if (!allDefaultAddress.length && !isDuplicate()) {
        setAddress('');
        setPickupServices([PICKUP_SERVICE_OPTION[0].value]);
      }

      if (allDefaultAddress.length && !isDuplicate()) {
        const defaultAddress = allDefaultAddress?.[0];
        setPickupServices(defaultAddress?.pickup_service_options);
        handleSelect(defaultAddress?.name, defaultAddress?.locationId);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const handleAddSavedAddress = (addr) => {
    handleSelect(addr.name, addr.locationId);
    setPickupServices(addr.pickup_service_options);
  };

  useEffect(() => {
    if (Object.keys(currentCustomer).length) {
      loadAddresses();
    }
  }, [currentCustomer]);

  useEffect(() => {
    // Check if there's an address match in userAddress

    if (Object.keys(pickupAddress).length) {
      const obj = { ...pickupAddress };

      if (userAddress.length) {
        const addrData = userAddress?.find(
          (pick) => pick?.pickup_formatted_address?.trim() === address
        );
        let pickupLocation = null;

        if (addrData) {
          pickupLocation = addrData?.id;
        }
        obj.pickupLocation = pickupLocation;
      }

      obj.pickup_service_options = pickupServices;

      setPickupAddress(obj);
    }
  }, [address, pickupServices]);

  useEffect(() => {
    if (serviceType === LTL_FREIGHT) {
      if (
        pickupServices.includes(PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        pickupServices.includes(PICKUP_RESIDENTIAL)
      ) {
        const updatedPickupServices = pickupServices.filter(
          (item) =>
            item !== PICKUP_BUSSINESS_WITH_DOCK_FORKLIFT &&
            item !== PICKUP_RESIDENTIAL
        );
        setPickupServices(updatedPickupServices);

        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
    }
  }, [serviceType, pickupServices]);

  useEffect(() => {
    if (!Object.keys(pickupAddress).length) {
      setAddress('');
      setIsChangeAdd(false);
      setUserAddress([]);
    }
    if (
      !isAutofilled &&
      isDuplicate() &&
      !address &&
      Object.keys(pickupAddress).length
    ) {
      const inputAdd = pickupAddress?.pickup_formatted_address
        ?.split('|')[0]
        .trim();
      setAddress(inputAdd);
      setIsAutofilled(true);
    }
  }, [pickupAddress]);

  return (
    <div className="flex flex-col gap-2 w-full items-start">
      <span className="font-medium ml-3">Ship From</span>
      <div className="flex flex-col bg-navy-20 p-3 w-full gap-4">
        <div className="flex flex-col items-start gap-2">
          <span className="text-gray-500">Pickup Address</span>
          <div className="flex flex-row justify-between items-center pr-2 w-full border gap-1 bg-white">
            <PlacesAutocomplete
              value={address ?? ''}
              onChange={(e) => onAddressChange(e)}
              onSelect={handleSelect}
              highlightFirstSuggestion
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div className="w-full">
                  <div className="flex gap-3 items-center">
                    <input
                      ref={pickupRef}
                      className=" focus:outline-none w-full text-sm py-3 px-2"
                      {...getInputProps({
                        placeholder: 'Type address',
                        onKeyDown: (e) => handleKeyDown(e, suggestions)
                      })}
                    />
                  </div>

                  {IsChangeAdd && (
                    <div className="overflow-scroll max-h-48">
                      <div className="mt-2 space-y-3 px-2 py-2 border shadow-lg">
                        <div className="mt-3">
                          {suggestions.length > 0 && (
                            <>
                              <div className="flex flex-col gap-2 items-start">
                                <p className="text-gray-400">Other Address</p>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  const suggestionProps =
                                    getSuggestionItemProps(suggestion);
                                  const handleClick = (event) => {
                                    setPickupServices([
                                      PICKUP_SERVICE_OPTION[0].value
                                    ]);
                                    suggestionProps.onClick(event); // Call the original onClick handler
                                  };
                                  return (
                                    <div
                                      key={suggestion.placeId}
                                      {...suggestionProps}
                                      onClick={handleClick}
                                      className={`flex justify-start gap-2 items-start cursor-pointer ${suggestion.active && 'bg-navy-50'}`}
                                    >
                                      <input
                                        className="quickQuote-radio "
                                        type="radio"
                                        value={suggestion.placeId}
                                      />
                                      <p className="quickQuote-description">
                                        {suggestion.description.trim()}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                              <hr className="mt-3" />
                            </>
                          )}
                        </div>
                        <p className="text-gray-400">Default Address</p>
                        <div className="flex flex-col mb-4 mt-4">
                          {userAddress
                            ?.filter((addr) => addr.isDefault)
                            ?.map((addr) => {
                              return (
                                <label
                                  key={addr?.id}
                                  className="flex flex-row flex-wrap gap-2 justify-start items-center cursor-pointer"
                                >
                                  <input
                                    type="radio"
                                    className="quickQuote-radio"
                                    value={addr.id}
                                    checked={addr.name === address}
                                    onChange={() =>
                                      handleSelect(addr.name, addr.locationId)
                                    }
                                  />
                                  <p>{addr.name}</p>
                                </label>
                              );
                            })}
                        </div>
                        <hr />
                        <div>
                          <p className="text-gray-400">Saved Address</p>
                          <div className="mb-4 mt-4 flex flex-col gap-2 ">
                            {userAddress
                              ?.filter((addr) => !addr.isDefault)
                              ?.map((addr) => {
                                return (
                                  <label
                                    key={addr?.id}
                                    className="flex flex-row flex-wrap gap-2 justify-start items-center cursor-pointer"
                                  >
                                    <input
                                      type="radio"
                                      className="quickQuote-radio"
                                      value={addr.id}
                                      onChange={() =>
                                        handleAddSavedAddress(addr)
                                      }
                                      checked={addr.name === address}
                                    />
                                    <p>{addr.name}</p>
                                  </label>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
            {!IsChangeAdd && (
              <div className="">
                <button
                  type="button"
                  onClick={() => setIsChangeAdd(true)}
                  className="flex py-1 px-2 gap-1 items-center h-7 whitespace-normal max-w-max border rounded-3xl border-navy-500  text-navy-500 
     text-sm break-words"
                >
                  <IoLocationSharp size={20} />
                  Change
                </button>
              </div>
            )}
          </div>
          {validate && !address && (
            <span className="text-sm text-red-700">
              Please add a Pickup Location
            </span>
          )}
        </div>
        <hr />
        <div className="flex flex-col items-start gap-4">
          <span className="text-gray-500 font-medium">
            Select Pickup Service
          </span>
          {PICKUP_SERVICE_OPTION.map((item) => (
            <PickupServiceItem key={item.value} pickupServiceItem={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PickupAddressCard;
